import albertoGraziadeiDepliantPic1 from 'assets/images/events/alberto-graziadei/depliant/1.jpeg';
import albertoGraziadeiDepliantPic2 from 'assets/images/events/alberto-graziadei/depliant/2.jpeg';
import albertoGraziadeiDepliantPic3 from 'assets/images/events/alberto-graziadei/depliant/3.jpeg';
import albertoGraziadeiRecensioniPic1 from 'assets/images/events/alberto-graziadei/recensioni/1.jpeg';
import albertoGraziadeiRecensioniPic2 from 'assets/images/events/alberto-graziadei/recensioni/2.jpeg';
import arteSacraRecensioniPic1 from 'assets/images/events/arte-sacra/recensioni/1.jpeg';
import arteSacraRecensioniPic2 from 'assets/images/events/arte-sacra/recensioni/2.jpeg';
import arteSacraRecensioniPic3 from 'assets/images/events/arte-sacra/recensioni/3.jpeg';
import brunoDegasperiCatalogoPic1 from 'assets/images/events/bruno-degasperi/catalogo/1.jpeg'
import brunoDegasperiCatalogoPic2 from 'assets/images/events/bruno-degasperi/catalogo/2.jpeg'
import brunoDegasperiCatalogoPic3 from 'assets/images/events/bruno-degasperi/catalogo/3.jpeg'
import brunoDegasperiRecensioniPic1 from 'assets/images/events/bruno-degasperi/recensioni/1.jpeg'
import brunoDegasperiRecensioniPic2 from 'assets/images/events/bruno-degasperi/recensioni/2.jpeg'
import brunoDegasperiRecensioniPic3 from 'assets/images/events/bruno-degasperi/recensioni/3.jpeg'
import carloBonacinaDepliantPic1 from 'assets/images/events/carlo-bonacina/depliant/1.jpeg';
import carloBonacinaDepliantPic2 from 'assets/images/events/carlo-bonacina/depliant/2.jpeg';
import carloBonacinaDepliantPic3 from 'assets/images/events/carlo-bonacina/depliant/3.jpeg';
import carloBonacinaRecensioniPic1 from 'assets/images/events/carlo-bonacina/recensioni/1.jpeg';
import cirilloGrottRecensioniPic1 from 'assets/images/events/cirillo-grott/recensioni/1.jpeg';
import cirilloGrottRecensioniPic2 from 'assets/images/events/cirillo-grott/recensioni/2.jpeg';
import cirilloGrottRecensioniPic3 from 'assets/images/events/cirillo-grott/recensioni/3.jpeg';
import ginoNovelloDepliantPic1 from 'assets/images/events/gino-novello/depliant/1.jpeg';
import ginoNovelloDepliantPic2 from 'assets/images/events/gino-novello/depliant/2.jpeg';
import ginoNovelloDepliantPic3 from 'assets/images/events/gino-novello/depliant/3.jpeg';
import ginoNovelloRecensioniPic1 from 'assets/images/events/gino-novello/recensioni/1.jpeg';
import ginoNovelloRecensioniPic2 from 'assets/images/events/gino-novello/recensioni/2.jpeg';
import ginoNovelloRecensioniPic3 from 'assets/images/events/gino-novello/recensioni/3.jpeg';
import ginoNovelloRecensioniPic4 from 'assets/images/events/gino-novello/recensioni/4.jpeg';
import giovanniBattistaPiranesiCatalogoPic1 from 'assets/images/events/giovanni-battista-piranesi/catalogo/1.jpeg'
import giovanniBattistaPiranesiCatalogoPic2 from 'assets/images/events/giovanni-battista-piranesi/catalogo/2.jpeg'
import giovanniBattistaPiranesiRecensioniPic1 from 'assets/images/events/giovanni-battista-piranesi/recensioni/1.jpeg'
import giovanniBattistaPiranesiRecensioniPic2 from 'assets/images/events/giovanni-battista-piranesi/recensioni/2.jpeg'
import giovanniBattistaPiranesiRecensioniPic3 from 'assets/images/events/giovanni-battista-piranesi/recensioni/3.jpeg'
import goyaCatalogoPic1 from 'assets/images/events/goya/catalogo/1.jpeg';
import goyaCatalogoPic2 from 'assets/images/events/goya/catalogo/2.jpeg';
import goyaRecensioniPic1 from 'assets/images/events/goya/recensioni/1.jpeg';
import goyaRecensioniPic2 from 'assets/images/events/goya/recensioni/2.jpeg';
import graficaUnghereseCatalogoPic1 from 'assets/images/events/grafica-ungherese/catalogo/1.jpeg';
import graficaUnghereseCatalogoPic2 from 'assets/images/events/grafica-ungherese/catalogo/2.jpeg';
import graficaUnghereseRecensioniPic1 from 'assets/images/events/grafica-ungherese/recensioni/1.jpeg';
import graficaUnghereseRecensioniPic2 from 'assets/images/events/grafica-ungherese/recensioni/2.jpeg';
import hokusaiCatalogoPic1 from 'assets/images/events/hokusai/catalogo/1.jpeg';
import hokusaiCatalogoPic2 from 'assets/images/events/hokusai/catalogo/2.jpeg';
import ilarioTomasiDepliantPic1 from 'assets/images/events/ilario-tomasi/depliant/1.jpeg';
import ilarioTomasiDepliantPic2 from 'assets/images/events/ilario-tomasi/depliant/2.jpeg';
import ilarioTomasiDepliantPic3 from 'assets/images/events/ilario-tomasi/depliant/3.jpeg';
import ilarioTomasiDepliantPic4 from 'assets/images/events/ilario-tomasi/depliant/4.jpeg';
import ilarioTomasiDepliantPic5 from 'assets/images/events/ilario-tomasi/depliant/5.jpeg';
import ilarioTomasiRecensioniPic1 from 'assets/images/events/ilario-tomasi/recensioni/1.jpeg';
import incisioniOriginaliSpagnoleCatalogoPic1 from 'assets/images/events/incisioni-originali-spagnole/catalogo/1.jpeg';
import incisioniOriginaliSpagnoleCatalogoPic2 from 'assets/images/events/incisioni-originali-spagnole/catalogo/2.jpeg';
import inaugurazioneStamperiaFogolinoGraficaRecensioniPic2 from 'assets/images/events/inaugurazione-stamperia-fogolino-grafica/recensioni/2.jpeg';
import {
	default as inaugurazioneRecensioniPic1,
	default as inaugurazioneStamperiaFogolinoGraficaRecensioniPic1,
} from 'assets/images/events/inaugurazione/recensioni/1.jpeg';
import inaugurazioneRecensioniPic2 from 'assets/images/events/inaugurazione/recensioni/2.jpeg';
import inaugurazioneRecensioniPic3 from 'assets/images/events/inaugurazione/recensioni/3.jpeg';
import joleDAgostinCatalogoPic1 from 'assets/images/events/jole-d-agostin/catalogo/1.jpeg';
import joleDAgostinCatalogoPic2 from 'assets/images/events/jole-d-agostin/catalogo/2.jpeg';
import joleDAgostinRecensioniPic1 from 'assets/images/events/jole-d-agostin/recensioni/1.jpeg';
import joleDAgostinRecensioniPic2 from 'assets/images/events/jole-d-agostin/recensioni/2.jpeg';
import marcoBerlandaCatalogoPic1 from 'assets/images/events/marco-berlanda/catalogo/1.jpeg';
import marcoBerlandaCatalogoPic2 from 'assets/images/events/marco-berlanda/catalogo/2.jpeg';
import marcoBerlandaCatalogoPic3 from 'assets/images/events/marco-berlanda/catalogo/3.jpeg';
import marcoBerlandaCatalogoPic4 from 'assets/images/events/marco-berlanda/catalogo/4.jpeg';
import marcoBerlandaRecensioniPic1 from 'assets/images/events/marco-berlanda/recensioni/1.jpeg';
import marcoBertoldiDepliantPic1 from 'assets/images/events/marco-bertoldi/depliant/1.jpeg';
import marcoBertoldiDepliantPic2 from 'assets/images/events/marco-bertoldi/depliant/2.jpeg';
import marcoBertoldiDepliantPic3 from 'assets/images/events/marco-bertoldi/depliant/3.jpeg';
import marcoBertoldiRecensioniPic1 from 'assets/images/events/marco-bertoldi/recensioni/1.jpeg';
import marcoBertoldiRecensioniPic2 from 'assets/images/events/marco-bertoldi/recensioni/2.jpeg';
import marianoFracalossiDepliantPic1 from 'assets/images/events/mariano-fracalossi/depliant/1.jpeg';
import marianoFracalossiDepliantPic2 from 'assets/images/events/mariano-fracalossi/depliant/2.jpeg';
import marianoFracalossiDepliantPic3 from 'assets/images/events/mariano-fracalossi/depliant/3.jpeg';
import marianoFracalossiDepliantPic4 from 'assets/images/events/mariano-fracalossi/depliant/4.jpeg';
import marianoFracalossiDepliantPic5 from 'assets/images/events/mariano-fracalossi/depliant/5.jpeg';
import marianoFracalossiDepliantPic6 from 'assets/images/events/mariano-fracalossi/depliant/6.jpeg';
import marianoFracalossiDepliantPic7 from 'assets/images/events/mariano-fracalossi/depliant/7.jpeg';
import marianoFracalossiRecensioniPic1 from 'assets/images/events/mariano-fracalossi/recensioni/1.jpeg';
import marianoFracalossiRecensioniPic2 from 'assets/images/events/mariano-fracalossi/recensioni/2.jpeg';
import martinDemetzDepliantPic1 from 'assets/images/events/martin-demetz/depliant/1.jpeg';
import martinDemetzRecensioniPic1 from 'assets/images/events/martin-demetz/recensioni/1.jpeg';
import martinDemetzRecensioniPic2 from 'assets/images/events/martin-demetz/recensioni/2.jpeg';
import massimoRadicioniRecensioniPic1 from 'assets/images/events/massimo-radicioni/recensioni/1.jpeg';
import massimoRadicioniRecensioniPic2 from 'assets/images/events/massimo-radicioni/recensioni/2.jpeg';
import nuovaSedeFogolinoMainPic1 from 'assets/images/events/nuova-sede-fogolino/main/1.jpeg';
import nuovaSedeFogolinoMainPic2 from 'assets/images/events/nuova-sede-fogolino/main/2.jpeg';
import nuovaSedeFogolinoRecensioniPic1 from 'assets/images/events/nuova-sede-fogolino/recensioni/1.jpeg';
import nuovaSedeFogolinoRecensioniPic2 from 'assets/images/events/nuova-sede-fogolino/recensioni/2.jpeg';
import nuovaSedeFogolinoRecensioniPic3 from 'assets/images/events/nuova-sede-fogolino/recensioni/3.jpeg';
import pietroDianaRecensioniPic1 from 'assets/images/events/pietro-diana/recensioni/1.jpeg';
import pietroDianaRecensioniPic2 from 'assets/images/events/pietro-diana/recensioni/2.jpeg';
import pietroParigiDepliantPic1 from 'assets/images/events/pietro-parigi/depliant/1.jpeg';
import pietroParigiDepliantPic2 from 'assets/images/events/pietro-parigi/depliant/2.jpeg';
import pietroParigiDepliantPic3 from 'assets/images/events/pietro-parigi/depliant/3.jpeg';
import pietroParigiDepliantPic4 from 'assets/images/events/pietro-parigi/depliant/4.jpeg';
import pietroParigiDepliantPic5 from 'assets/images/events/pietro-parigi/depliant/5.jpeg';
import pietroParigiDepliantPic6 from 'assets/images/events/pietro-parigi/depliant/6.jpeg';
import pietroParigiDepliantPic7 from 'assets/images/events/pietro-parigi/depliant/7.jpeg';
import pietroParigiDepliantPic8 from 'assets/images/events/pietro-parigi/depliant/8.jpeg';
import pietroParigiDepliantPic9 from 'assets/images/events/pietro-parigi/depliant/9.jpeg';
import tamayoRecensioniPic1 from 'assets/images/events/tamayo/recensioni/1.jpeg';
import tamayoRecensioniPic2 from 'assets/images/events/tamayo/recensioni/2.jpeg';
import tamayoCatalogoPic1 from 'assets/images/events/tamayo/catalogo/1.jpeg';
import tamayoCatalogoPic2 from 'assets/images/events/tamayo/catalogo/2.jpeg';
import stamperiaRecensioniPic1 from 'assets/images/events/stamperia/recensioni/1.jpeg';
import stamperiaRecensioniPic2 from 'assets/images/events/stamperia/recensioni/2.jpeg';

export const galleriaDates = ['1966', '1970', '1980', '1990', '2000', '2010', '2020'];
export const marianoSkills = [
	'grafica incisa',
	'pittura',
	'disegno',
	'scenografia',
	'gioielli',
	'vetrate',
	'mosaico',
	'smalto',
	'affresco',
	'varia',
];
export const marianoData = `Mariano Fracalossi nasce a Trento il 22 agosto 1923 nell’antico, popoloso rione di San Martino.
Il padre Attilio, pittore decoratore, nel difficile periodo tra il 1927 ed il 1932 è costretto a lasciare la famiglia per trasferirsi in Argentina ove riesce ad esercitare la professione di decoratore e scenografo, attività, quest’ultima, per cui ha precedentemente frequentato a Milano uno specifico corso di studi. 
Il piccolo Mariano segue il percorso scolastico in istituti del centro cittadino, inizialmente le scuole elementari “Regina Elena” nel vecchio rione San Pietro, luogo a cui rimarrà sempre particolarmente affezionato, quindi le scuole “Verdi” rivelando, sin da giovanissimo, agli attenti insegnanti, la maestra Proclemer ed il maestro Ducati, una spiccata abilità nel disegno.

Nel periodo compreso tra il 1934 ed il 1937 frequenta il corso di Avviamento Industriale presso le Scuole Buonarroti ove segue, con particolare attenzione e passione, i laboratori di modellazione plastica del celebre scultore Stefano Zuech.
Nel 1938, a soli 15 anni, inizia il tirocinio di apprendista pittore decoratore e, completata la sua formazione professionale, a 18 anni, segue il padre nell’attività di pittore decoratore, coadiuvandolo anche in quella di scenografo presso numerosi teatri parrocchiali della città.

Dopo il servizio militare a Roma nel 1° Reggimento Granatieri, nel 1942 viene inviato in una Corsica occupata sia da truppe italiane che tedesche con le quali, dopo l’8 settembre 1943, fortunatamente gli scontri sono rari e privi di rilevanza.
All’inizio del 1944 il suo Reggimento si trasferisce a Sedini, in provincia di Sassari ove il giovane Mariano, incline da sempre all’arte, rimane affascinato dall’antica civiltà nuragica e dalle sue ancora oggi misteriose costruzioni. Giunti gli Inglesi, il Reggimento, quasi al completo, viene arruolato nel Corpo di Liberazione Italiano e trasferito presso Napoli ove i militari vengono addestrati ed equipaggiati. 
Mariano Fracalossi  viene assegnato in Emilia; la fine del conflitto lo vede, con il grado di sergente, combattente nei pressi di Bologna.
Congedato, nel 1946, riprende l’attività di pittore decoratore ma desiderando fortemente perfezionarsi, nel 1947, grazie all’aiuto ed all’ospitalità di un padre domenicano suo caro amico, riprende gli studi artistici a Firenze diplomandosi presso l’Istituto Superiore d’Arte di Porta Romana e conseguendo anche il magistero nel 1949.

Ritornato in Trentino riprende l’attività di pittore decoratore svolgendo in contemporanea l’azione didattica,  iniziata con alcune supplenze in disegno presso alcuni istituti della città,  e l’attività di scenografo, soprattutto per il Teatro San Pietro con il quale stabilisce un lungo e proficuo sodalizio. Grazie alle sue ardite intuizioni innova fortemente la scenografia teatrale trentina collaborando alle celebri, apprezzate riviste dell’amico regista Marcello Voltolini.

Da sempre impegnato anche in un serio, costante percorso artistico–creativo personale, Mariano Fracalossi, nel 1951, viene ammesso alla Quadriennale Nazionale d’Arte di Torino con un dipinto ad olio su tela dal titolo “I Giocatori di carte”. L’evento viene segnalato sul quotidiano locale l’Adige da Giulio Decarli, primo critico ad occuparsi dell’artista trentino e ad intuirne il successivo, proficuo percorso.
Invitato dal grande incisore Remo Wolf, nel 1953 presenta a Trento, al “Circolo Bronzetti”, la sua prima mostra personale, un’apprezzata esposizione di opere grafiche.

Nel 1954 si sposa con l’insegnante bolognese Laura Lenzi da cui avrà, nel 1958, il figlio Adriano e, nel 1966, la figlia Lorenza.

Inizia in questo periodo il suo profondo interesse per le difficili tecniche calcografiche, l’acquaforte, l’acquatinta e la vernice molle.
Il 1956 si rivela per Mariano un anno di particolare impegno; consegue infatti l’abilitazione all’insegnamento del disegno e della storia dell’arte e viene nominato docente di ruolo in disegno alla Scuola media “Segantini” di Trento, ruolo che lascerà, dopo ben trent’anni di professione, al raggiungimento della  pensione nel 1979.

Nello stesso anno esperimenta per la prima volta la complessa tecnica dell’affresco, impreziosendo con figure di giovani Santi le cappelle dell’Oratorio di San Pietro; realizza infine ad olio tredici tavole, tutte giocate sul colore blu, per un’originale Via Crucis, oggi purtroppo dispersa.
Presente in numerose mostre collettive in varie città italiane e conosciuto per alcune personali in Trentino, viene ammesso, nel 1959, all’8° Quadriennale Nazionale d’Arte di Roma. Da sempre attento anche alla promozione e valorizzazione di chi si occupa con passione del settore artistico, pochi anni dopo, nel 1962, assume la segreteria provinciale del Sindacato Italiano Artisti Belle Arti (SIABA), prestigioso ruolo che ricoprirà per ben undici anni organizzando, coadiuvato da preziosi volontari, importanti esposizioni a Trento, Bassano del Grappa, Torino, Novara, Venezia e Roma dedicate all’arte trentina ed ai suoi grandi interpreti come Gino Pancheri e Umberto Moggioli.

Ricostituita nel 1966 l’UCAI, Unione Cattolica Artisti Italiani viene aperta da Fracalossi la Galleria d’Arte Fogolino, dedicata al celebre pittore cinquecentesco autore di affreschi posti sul soffitto di alcuni spazi espositivi della Galleria stessa ospitata presso l’antico Palazzo Lodron. Galleria che diverrà per molti decenni, fulcro e centro della cultura e dell’arte trentina e non solo; tuttora attiva ed ospitata in una vicina sede è attualmente gestita dal figlio Adriano, anch’egli affermato artista.
Nel 1969 Mariano Fracalossi viene chiamato a far parte dell’Accademia del Buonconsiglio, poi Accademia degli Accesi e nel 1970 viene insignito del Drappo di S.Vigilio, importante tributo della sua città natale. Altro importante riconoscimento gli arriverà successivamente, nel 1972, anno in cui verrà invitato ad entrare nell’antica, prestigiosa Accademia degli Agiati di Rovereto.
Periodo di intenso, proficuo impegno sociale ed artistico sono per Mariano anche i primi anni Settanta. 

Nel 1970 infatti Fracalossi, insieme ad alcuni esponenti della cultura, della politica  e dell’arte trentina (Luigi Degasperi, Luigi Mattei, Remo Wolf e Ferruccio Pisoni), fonda il Comitato Trentino per la diffusione della Cultura che realizza l’anno seguente, nel 1971 a Trento e a Roma, due grandi eventi artistici, la mostra su Tullio Garbari e la rassegna “10 + 10. Dieci pittori e dieci incisori trentini del XX secolo”. L’interessante iniziativa, nata in collaborazione con la Sovrintendenza alle Gallerie Roma 2 – Arte contemporanea, viene poi purtroppo frenata per incomprensioni e disguidi organizzativi. 

In seguito alla decisione dell’Università Popolare di Trento, nel 1969, di sospendere i corsi di disegno che si tenevano già da molti anni, condotti da insegnanti di prestigio tra cui Luigi Degasperi, Guido Polo, Remo Wolf e lo stesso Fracalossi, sempre nel 1970, Mariano Fracalossi, insieme ad una ventina di appassionati, crea il Gruppo Studio Arti Visuali, straordinaria ed innovativa esperienza culturale, da lui diretta con sensibilità e passione fino all’anno 2000 e  nel cui laboratorio di pittura si formeranno numerose ed importanti personalità artistiche trentine. Pregevoli ed innumerevoli le iniziative e le esposizioni promosse dal Gruppo, tra cui, assolutamente da citare, l’esperienza del 1979 in cui il GSAV collabora con una quarta elementare delle Scuole “Verdi” e con la sua insegnante, la maestra Dorigatti. Dall’impegno artistico -  didattico di molti nascerà l’interessante, tenera ed originale pubblicazione “Così la mia città: Trento”, volume interamente illustrato e commentato dai giovanissimi alunni.

Nel 1986 viene nasce poi la Cerchia, sodalizio di amici uniti nel nome dell’arte, ispirato e guidato da Mariano Fracalossi, indicato da tutti con rispettoso affetto come il “Professore”. Innumerevoli le attività del gruppo; esposizioni personali e collettive in Trentino, in Italia e all’estero, scambi culturali ed artistici, la pubblicazione del giornalino “Arte & arte” ed ancora interventi in favore delle problematiche del settore artistico e dei suoi promotori.

Continua comunque, sempre intensa negli anni, l’attività espositiva e creativa di Fracalossi che si segnala al grande pubblico nel 1995, in occasione della visita a Trento di Papa Giovanni Paolo II, per la progettazione e la realizzazione, assieme all’amico artista Marcello Baldessari, dell’urna–reliquiario per il nuovo Beato Nepomuceno de Tschiderer. 
L’artista trentino raccoglie ovunque, anche all’estero, come nell’ultima, ampia personale di Strasburgo “St’Art 2004” del 2004, apprezzamenti di pubblico e critica per la sua fresca, immediatamente riconoscibile, cifra stilistica che narra con delicata poesia un mondo di leggende con fantastiche architetture ed improbabili, affascinanti, paesaggi.

Mariano Fracalossi, l’Artista, il Professore, dopo breve malattia, scompare a Trento nel maggio del 2004 all’età di quasi ottantuno anni.  
Un’esistenza, la sua, che veramente si può definire consacrata all’Arte.

Testo di Nicoletta Tamanini, dal catalogo « Mariano Fracalossi (1923-2004), Un’avventura nel mondo dell’arte ».


Esposizioni personali (selezione):

1953 
Trento, Centro Culturale “ Bronzetti”
Levico Terme (Trento), Azienda Turismo

1959
Trento, Galleria “Gli Specchi”

1964
Riva del Garda (Trento), Galleria d’Arte “La Vela”
Avezzano (L’Aquila), Galleria d’Arte “La Trozzella”

1967
Vicenza, Galleria d’Arte “L’incontro”

1969
Trento, Galleria Fogolino

1970
Firenze, Galleria “Il Vaglio”
Brescia, “Piccola Galleria”
Catania, “Galleria delle Arti”

1972
Torino, Galleria “Viotti”
Rovereto (Trento), Galleria “Pancheri”
Alte Ceccato (Vicenza), Galleria d’Arte “Modigliani”

1973
Pisa, Galleria d’arte “Macchi”
Torino, Galleria “Gold Square”
Catania, “Galleria delle Arti”

1974
Chieri (Torino), Galleria “La Semantica”
Catania, Galleria “Gallery” 
Casale Monferrato, Casa d’Arte “L’Aleramica”
Giarre (Catania), Galleria d’Arte “Underground”
Como, Galleria d’Arte “Il Salotto”

1975
Trento, Galleria Fogolino “ A quattro mani”

1976
Venezia, Galleria dell’Incisione, “Venezia viva”
Bassano del Grappa ( Vicenza), Galleria S. Marco

1977
Bolzano, Galleria “Le chances de l’art”
Pisa, Galleria “Macchi”

1978
Padova, Galleria “La cupola”
Trento, Galleria Fogolino

1979
Folgaria (Trento), Casa della Cultura
Torino, Galleria Doria

1980
Trento, Galleria Fogolino
Torino, Galleria Doria

1982
Padova, “Galleria Selearte I°”
  (personale di pittura, grafica incisa e disegnata)
Trento, Palazzo delle Albere
   (Spazio Atelier, Incisione)

1983
Vicenza, Galleria Bacchiglione

1984
Carpenedo (Mestre), Galleria “La Cella” 
Trento, Galleria Fogolino “Venticinque = cinque x cinque”
Padova, Galleria dell’Acquario, “Dieci acqueforti per dieci poesie di Biagio Marin”

1985
Trento, Galleria Fogolino, “La Cometa su Trento e altre storie”

1986
Brescia, Piccola Galleria
Bologna, “Arte Fiera”

1987
Bologna, “Arte Fiera”

1988
Bologna, “Arte Fiera”

1989
Schio (Vicenza), Galleria “Fonte”
Bologna, “Arte Fiera”

1990
Trieste, “Galleria Cartesius”

1991
Forlì, Galleria “Farneti”

1992
Palazzolo sull’Oglio (Brescia), Galleria “la Roggia”

1994
Trento, Palazzo Tabarelli, Banca Calderari 
“Via Crucis” (per Vermiglio)

1995
Bolzano, Galleria Civica di Piazza Domenicani

1996
Rovereto (Trento), Mart
mostra antologica

1997
Trento, Palazzo Trentini
mostra antologica 

1999
Padova, Galleria dell’Acquario

2001
Guardia di Folgaria (Trento)

2002
Bassano del Grappa (Vicenza), Galleria Scrimin

2003
Innsbruck (Austria), Gallerie im Andechshof

2004
Strasburgo (Francia), “St’Art 2004”`;

export const eventPages = [
	{
		pageUrl: 'inaugurazione',
		mainContent: {
			title: 'Inaugurazione Galleria M. Fogolino',
			startDate: new Date('03/03/1966'),
			content: `La prima sede è in via Calepina, in uno spazio al piano terra di Palazzo Sardagna. Il suo nome lo deve al pittore Marcello Fogolino (1483 / 88 circa – dopo il 1558), autore degli affreschi presenti sul soffitto della sala. L’inaugurazione ha luogo il 3 marzo 1966, alla presenza delle autorità, tra le quali l’Arcivescovo, in quanto la Galleria è anche sede del gruppo Ucai (Unione cattolica artisti italiani) di Trento. Per l’occasione viene allestita una rassegna di un gruppo di artisti tra i più rappresentativi della realtà trentina. Sono Marco Bertoldi, Carlo Bonacina, Bruno Colorio, Luigi Degasperi, Martino Demetz, Mariano Fracalossi, Gino Novello, Guido Polo, Luigi Senesi (“una sicura promessa dell’arte trentina” lo definisce il critico Rinaldo Sandri in un articolo sull’Adige), Cesarina Seppi e Remo Wolf.`,
		},
		mainPictures: [],
		catalogoPictures: [],
		recensioniPictures: [
			{ picture: [{ url: inaugurazioneRecensioniPic1 }], pictureAlt: 'inaugurazione-fogolino-1' },
			{ picture: [{ url: inaugurazioneRecensioniPic2 }], pictureAlt: 'inaugurazione-fogolino-2' },
			{ picture: [{ url: inaugurazioneRecensioniPic3 }], pictureAlt: 'inaugurazione-fogolino-3' },
		],
		depliantPictures: [],
	},
	{
		pageUrl: 'inaugurazione-stamperia-fogolino-grafica',
		mainContent: {
			title: 'Inaugurazione Stamperia Fogolino Grafica',
			startDate: new Date('11/02/1979'),
			content: `Nel novembre del 1979 viene aperta in Via Esterle a Trento la stamperia Fogolino Grafica. Dell'attività di stampa si occuperà da quel momento Adriano Fracalossi, allora ventenne e studente d'arte al DAMS di Bologna. 
        Nell'occasione viene presentata una cartella di incisioni di piccolo formato nelle varie tecniche calcografiche (acquaforte, acquatinta, punta secca, zucchero, impronta e xilografia), eseguite da Remo Wolf, Carlo Bonacina, Lea Botteri, Mariano ed Adriano Fracalossi.`,
		},
		mainPictures: [],
		catalogoPictures: [],
		recensioniPictures: [
			{
				picture: [{ url: inaugurazioneStamperiaFogolinoGraficaRecensioniPic1 }],
				pictureAlt: 'inaugurazione-stamperia-fogolino-grafica-1',
			},
			{
				picture: [{ url: inaugurazioneStamperiaFogolinoGraficaRecensioniPic2 }],
				pictureAlt: 'inaugurazione-stamperia-fogolino-grafica-2',
			},
		],
		depliantPictures: [],
	},
	{
		pageUrl: 'massimo-radicioni',
		mainContent: {
			title: 'Massimo Radicioni',
			startDate: new Date('03/15/1966'),
			content: `Massimo Radicioni (Bolzano,1942), per il quale Rinaldo Sandri, su L'Adige,  parla di “maturità di linguaggio” e di “una scioltezza narrativa eccezionale”. Ma anche di “una sorta di costante polemica, radice di rivolta sociale, ma sovente liberata in anarchia”. Poi prosegue “volendo approfondire si può affermare che tale radice, ai giorni nostri appare abbastanza ingenuamente scoperta, in quasi tutti i gruppi culturali e artistici d’avanguardia, tanto che ha potuto essere definita la sola centrale energetica alla quale sembrano in grado di attingere i rappresentanti della ricerca del pensiero e dell’arte, i cosiddetti “impegnati”. Un fatto che si riscontra da qualche tempo, oltreché’ a livello dei salotti culturali, presso gli ambienti universitari.”`,
		},
		mainPictures: [],
		catalogoPictures: [],
		recensioniPictures: [
			{ picture: [{ url: massimoRadicioniRecensioniPic1 }], pictureAlt: 'massimo-radicioni-1' },
			{ picture: [{ url: massimoRadicioniRecensioniPic2 }], pictureAlt: 'massimo-radicioni-2' },
		],
		depliantPictures: [],
	},
	{
		pageUrl: 'marco-bertoldi',
		mainContent: {
			title: 'Marco Bertoldi',
			startDate: new Date('05/06/1966'),
			endDate: new Date('05/22/1966'),
			content: `Le recensioni alla mostra di Marco Bertoldi di Rinaldo Sandri e Gian Pacher, sono pubblicate rispettivamente su l'Adige e l'Alto Adige nel maggio 1966. Una terza, non firmata, è stata pubblicata nello stesso periodo su Il Gazzettino.`,
		},
		mainPictures: [],
		catalogoPictures: [],
		recensioniPictures: [
			{ picture: [{ url: marcoBertoldiRecensioniPic1 }], pictureAlt: 'marco-bertoldi-1' },
			{ picture: [{ url: marcoBertoldiRecensioniPic2 }], pictureAlt: 'marco-bertoldi-2' },
		],
		depliantPictures: [
			{ picture: [{ url: marcoBertoldiDepliantPic1 }], pictureAlt: 'marco-bertoldi-3' },
			{ picture: [{ url: marcoBertoldiDepliantPic2 }], pictureAlt: 'marco-bertoldi-4' },
			{ picture: [{ url: marcoBertoldiDepliantPic3 }], pictureAlt: 'marco-bertoldi-5' },
		],
	},
	{
		pageUrl: 'martin-demetz',
		mainContent: {
			title: 'Martin Demetz',
			startDate: new Date('10/09/1966'),
			endDate: new Date('10/27/1966'),
			content: `Segnaliamo la recensione di Rinaldo Sandri su L'Adige ed un trafiletto in cui si informa di una mostra organizzata dalla Fogolino, con il gruppo di artisti trentini che fa riferimento alla Galleria, a Treviso, presso la Galleria Eridamo.`,
		},
		mainPictures: [],
		catalogoPictures: [],
		recensioniPictures: [
			{ picture: [{ url: martinDemetzRecensioniPic1 }], pictureAlt: 'martin-demetz-1' },
			{ picture: [{ url: martinDemetzRecensioniPic2 }], pictureAlt: 'martin-demetz-2' },
		],
		depliantPictures: [
			{ picture: [{ url: martinDemetzDepliantPic1 }], pictureAlt: 'martin-demetz-3' },
		],
	},
	{
		pageUrl: 'arte-sacra',
		mainContent: {
			title: 'Rassegna di arte sacra',
			startDate: new Date('12/15/1966'),
			content: `Nel mese di dicembre 1966 viene presentata alla Fogolino una rassegna di arte sacra con opere di M. Bertoldi, C. Bonacina, M. Demetz, M. Fracalossi, G. Novello, L. Senesi e R. Wolf. 
      Alla mostra è abbinata quella che Rinaldo Sandri chiama una iniziativa singolare. Vengono distribuite 350 schede tra i visitatori per effettuare un sondaggio “per stabilire l'orientamento del gusto dei frequentatori delle gallerie trentine”. Ne vengono restituite compilate solo 43 e lo stesso Sandri commenta polemicamente che "a Trento gli amatori d'arte preferiscono le creazioni dei forestieri e non hanno imparato a farsi un idea dei propri conterranei". Da notare, nell'articolo di Gian Pacher per L'Alto Adige, anche l'iniziativa che avviene in contemporanea alla Galleria Argentario, come reazione all'alluvione che il 4 novembre aveva colpito la città e danneggiato la medesima Galleria.`,
		},
		mainPictures: [],
		catalogoPictures: [],
		recensioniPictures: [
			{ picture: [{ url: arteSacraRecensioniPic1 }], pictureAlt: 'arte-sacra-1' },
			{ picture: [{ url: arteSacraRecensioniPic2 }], pictureAlt: 'arte-sacra-2' },
			{ picture: [{ url: arteSacraRecensioniPic3 }], pictureAlt: 'arte-sacra-3' },
		],
		depliantPictures: [],
	},
	{
		pageUrl: 'carlo-bonacina',
		mainContent: {
			title: 'Carlo Bonacina',
			startDate: new Date('01/07/1967'),
			endDate: new Date('01/21/1967'),
			content: `Così comincia la recensione di Rinaldo Sandri, pubblicata su L'Adige il 15 gennaio 1967, alla mostra di Carlo Bonacina: "Se la polvere del tempo è riuscita a coprire di un sufficiente numero di anni certi resti, riaprendo a luce le antiche opere d'arte, anfore, statue, colonne, maschere, affreschi, mosaici, suppellettili, siamo pronti a dare alle vestigia ritrovate un tempo mitico."`,
		},
		mainPictures: [],
		catalogoPictures: [],
		recensioniPictures: [
			{ picture: [{ url: carloBonacinaRecensioniPic1 }], pictureAlt: 'carlo-bonacina-1' },
		],
		depliantPictures: [
			{ picture: [{ url: carloBonacinaDepliantPic1 }], pictureAlt: 'carlo-bonacina-2' },
			{ picture: [{ url: carloBonacinaDepliantPic2 }], pictureAlt: 'carlo-bonacina-3' },
			{ picture: [{ url: carloBonacinaDepliantPic3 }], pictureAlt: 'carlo-bonacina-4' },
		],
	},
	{
		pageUrl: 'gino-novello',
		mainContent: {
			title: 'Gino Novello',
			startDate: new Date('10/04/1967'),
			endDate: new Date('10/24/1967'),
			content: `In occasione della personale di Gino Novello (dal 2 al 24 ottobre 1967) escono recensioni su Vita Trentina, Il Gazzettino e sul L'Adige. Se quella di Marchesoni, su Vita Trentina, si concentra sulle opere, le altre due allargano il discorso cercando di collocare i lavori di Novello all'interno del dibattito culturale di quel periodo.
      Così Rinaldo Sandri fa un preciso riferimento alla realtà culturale trentina:
      "L’attuale mostra si pone anche - se vogliamo - come confronto. Nella nostra città è in corso una più vasta rassegna d’avanguardia che intende documentare le posizioni dell’arte contemporanea. Si vedano certe soluzioni là prospettate unicamente sul pano del formalismo tecnicistico e si meditino - del pari – la pulizia formale, la fraseologia nitida di opere, come queste di Novello, che tendono orse allo stesso traguardo non già attraverso “contaminazioni” come direbbe qualcuno, con l’antico, ma che con l’antico hanno saputo conservare una lezione di profondo duraturo significato.`,
		},
		mainPictures: [],
		catalogoPictures: [],
		recensioniPictures: [
			{ picture: [{ url: ginoNovelloRecensioniPic1 }], pictureAlt: 'gino-novello-1' },
			{ picture: [{ url: ginoNovelloRecensioniPic2 }], pictureAlt: 'gino-novello-2' },
			{ picture: [{ url: ginoNovelloRecensioniPic3 }], pictureAlt: 'gino-novello-3' },
			{ picture: [{ url: ginoNovelloRecensioniPic4 }], pictureAlt: 'gino-novello-4' },
		],
		depliantPictures: [
			{ picture: [{ url: ginoNovelloDepliantPic1 }], pictureAlt: 'gino-novello-5' },
			{ picture: [{ url: ginoNovelloDepliantPic2 }], pictureAlt: 'gino-novello-6' },
			{ picture: [{ url: ginoNovelloDepliantPic3 }], pictureAlt: 'gino-novello-7' },
		],
	},
	{
		pageUrl: 'alberto-graziadei',
		mainContent: {
			title: 'Alberto Graziadei',
			startDate: new Date('01/23/1969'),
			endDate: new Date('02/01/1969'),
			content: `E' una figura per carattere un po' schiva nel contesto dell'arte trentina, un artista di quelli che , come dice Rinaldo Sandri,  guardano tutto, annotano tutto, ma si tengono in un clima di naturalezza , di semplicità, poiché ritengono che solo nello spontaneo, nella chiarezza che deriva dalla totale sincerità dei mezzi e del fine espressivo si fondino le premesse di ogni arte.`,
		},
		mainPictures: [],
		catalogoPictures: [],
		recensioniPictures: [
			{ picture: [{ url: albertoGraziadeiRecensioniPic1 }], pictureAlt: 'alberto-graziadei-1' },
			{ picture: [{ url: albertoGraziadeiRecensioniPic2 }], pictureAlt: 'alberto-graziadei-2' },
		],
		depliantPictures: [
			{ picture: [{ url: albertoGraziadeiDepliantPic1 }], pictureAlt: 'alberto-graziadei-3' },
			{ picture: [{ url: albertoGraziadeiDepliantPic2 }], pictureAlt: 'alberto-graziadei-4' },
			{ picture: [{ url: albertoGraziadeiDepliantPic3 }], pictureAlt: 'alberto-graziadei-5' },
		],
	},
	{
		pageUrl: 'mariano-fracalossi',
		mainContent: {
			title: 'Mariano Fracalossi',
			startDate: new Date('04/30/1969'),
			endDate: new Date('05/09/1969'),
			content: `In occasione della mostra di Mariano Fracalossi, presso la Galleria Fogolino, cosi scrive Gian Pacher sull'Alto Adige il 7 maggio 1969:
      “… Legato ad una dimensione immaginifica dove elementi della realtà e della fantasia si uniscono in una singolare simbiosi Fracalossi racconta ancore le sue fiabe sottili. Sono le storie dell’aquilone, ricche di vivezza espressiva e impaginate attraverso equilibri precisi.” Aggiunge poi che” ogni possibilità di equivoco simbolista… rimane estranea. Fracalossi si preoccupa di mettere in essere un suo mondo in cui taluni oggetti hanno solo la presenza della realtà. Il suo discorso grafico è invece libero nella fantasia, senza le problematiche dell’inconscio, per una scelta culturale di operare nella dimensione di una infanzia perduta e rimata nel cuore come il senso del bene più prezioso. Forse il suo “uomo fiore”…ha in qualche modo una oggettualità contemporanea, ma la limpidezza interpretativa vive in trasparenze liriche piene. Esse trovano piena rispondenza nel segno, nella semplicità grafica che pure che pure opera nell’attenta disposizione di piani e contrappunti. Un richiamo alla libertà e di spazi, di meravigliose scoperte, sono così gli elementi emozionali del racconto di Mariano Fracalossi.” 
      
      Nella sua recensione, comparsa su L'Adige il 5 maggio 1969, Rinaldo Sandri  interpreta l'opera di Mariano Fracalossi anche alla luce del dibattito culturale dell’epoca: "Il pittore sollecitato dalle costanti dei linguaggi visuali moderni, ha inteso dimostrare che l’invenzione è possibile anche nelle panie dello schema: che è possibile cioè approfondire il fatto sentimentale e poetico, lasciarlo intatto, pur servendosi delle accezioni del linguaggio scelto dalle nuove generazioni, trascendendo il fatto meramente polemico (la ribellione attuale alla società dei consumi, che sembra l’unico movente ritenuto valido) verso i lidi delle interne rive della psiche. Così l’aquilone può divenire nello stesso tempo sfida formale e emblema fantastico; contemporaneamente, nella sua attuazione grafica, resta un fatto espressivo a sé, indipendentemente dalla raffigurazione oggettiva, equilibrio e sintassi". Infine conclude dicendo che sono “…acqueforti che vanno comparate l’una all’altra per cogliere i prolungamenti di quella maglia fantastica, la cui punteggiatura - nell’insieme - costituisce una fonte di allettanti immagini allusive, ricche di sentimento e significato compositivo.”`,
		},
		mainPictures: [],
		catalogoPictures: [],
		recensioniPictures: [
			{ picture: [{ url: marianoFracalossiRecensioniPic1 }], pictureAlt: 'mariano-fracalossi-1' },
			{ picture: [{ url: marianoFracalossiRecensioniPic2 }], pictureAlt: 'mariano-fracalossi-2' },
		],
		depliantPictures: [
			{ picture: [{ url: marianoFracalossiDepliantPic1 }], pictureAlt: 'mariano-fracalossi-3' },
			{ picture: [{ url: marianoFracalossiDepliantPic2 }], pictureAlt: 'mariano-fracalossi-4' },
			{ picture: [{ url: marianoFracalossiDepliantPic3 }], pictureAlt: 'mariano-fracalossi-5' },
			{ picture: [{ url: marianoFracalossiDepliantPic4 }], pictureAlt: 'mariano-fracalossi-6' },
			{ picture: [{ url: marianoFracalossiDepliantPic5 }], pictureAlt: 'mariano-fracalossi-7' },
			{ picture: [{ url: marianoFracalossiDepliantPic6 }], pictureAlt: 'mariano-fracalossi-8' },
			{ picture: [{ url: marianoFracalossiDepliantPic7 }], pictureAlt: 'mariano-fracalossi-9' },
		],
	},
	{
		pageUrl: 'pietro-parigi',
		mainContent: {
			title: 'Pietro Parigi',
			startDate: new Date('02/03/1970'),
			endDate: new Date('02/17/1970'),
			content: `Dal 3 al 17 febbraio 1970 alla Galleria Fogolino vengono esposte opere grafiche, perlopiù xilografie, di Pietro Parigi (Settimello, 1892 – Firenze, 1990).
      Un artista fiorentino, di cultura cattolica, di cui cosi Piero Bargellini ricorda gli inizi: “Portato in calzoni corti in città, Pietro Parigi fu alloggiato presso un incisore in Borgo San Jacopo, dove si fondono e incidono i metalli. Cominciò quindi a incidere il rame e l’argento, promettendo di diventare un eccellente artigiano. Tornato a casa, la sera, Pietro Parigi si provava a modellare per conto proprio l’argilla e a fare lo scultore; si provava a disegnare e incidere per conto suo sul legno, facendo così le prime xilografie.”
      Tornato dalla guerra, nel 1923, assieme a Piero Bargellini (1897-1980),  Nicola Lisi (Scarperia, 1893 – Firenze, 1975) e al poeta Carlo Betocchi (Torino, 1899 - Bordighera, 1986) dà vita al mensile Calendario dei pensieri e delle pratiche solari.
      “Su quella rivista Pietro Parigi mostrò quale fosse la sua arte. Con piglio popolaresco che non sconfina mai nel folclore, e un sapore quasi amaro che non dà mai nello sconsolato. I soggetti sono quasi sempre umili e il modo di trattarli immediato e forte. La sua tecnica non è mai crudele. Pare che Parigi chieda al legno, non diciamo l’ispirazione, ma buon suggerimento per il suo lavoro. E la materia non gli si si ribella, non resta mai fredda né urtata.” (Piero Bargellini, in Omaggio a Pietro Parigi, Edizioni Santa croce, 1967).
      La sua opera grafica  è ora raccolta a Firenze in un museo, o Mostra permanente Pietro Parigi, presso la Basilica di Santa Croce.`,
		},
		mainPictures: [],
		catalogoPictures: [],
		recensioniPictures: [],
		depliantPictures: [
			{ picture: [{ url: pietroParigiDepliantPic1 }], pictureAlt: 'pietro-parigi-1' },
			{ picture: [{ url: pietroParigiDepliantPic2 }], pictureAlt: 'pietro-parigi-2' },
			{ picture: [{ url: pietroParigiDepliantPic3 }], pictureAlt: 'pietro-parigi-3' },
			{ picture: [{ url: pietroParigiDepliantPic4 }], pictureAlt: 'pietro-parigi-4' },
			{ picture: [{ url: pietroParigiDepliantPic5 }], pictureAlt: 'pietro-parigi-5' },
			{ picture: [{ url: pietroParigiDepliantPic6 }], pictureAlt: 'pietro-parigi-6' },
			{ picture: [{ url: pietroParigiDepliantPic7 }], pictureAlt: 'pietro-parigi-7' },
			{ picture: [{ url: pietroParigiDepliantPic8 }], pictureAlt: 'pietro-parigi-8' },
			{ picture: [{ url: pietroParigiDepliantPic9 }], pictureAlt: 'pietro-parigi-9' },
		],
	},
	{
		pageUrl: 'nuova-sede-fogolino',
		mainContent: {
			title: 'Inaugurazione della nuova sede della Galleria Fogolino',
			startDate: new Date('10/01/1971'),
			content: `Cosi scrive Gian Pacher su L’Alto Adige: "C’è innanzitutto da prendere atto della nascita di un nuovo centro culturale che si affiancherà a quelli già operanti (“Rosmini” e ”Bronzetti”) con un programma non solamente riservato alla pittura e alla scultura. Si tratta del centro culturale “Fogolino” che un tempo, fino alla scorsa stagione, era solamente - il termine non dispiaccia a nessuno - una galleria. La nuova “Fogolino” ha iniziato l’attività con una interessante mostra dedicata al piccolo formato e che vuole proprio in questa direzione stabilire un dialogo ”economico” con il consumatore. Le opere che sono esposte, tutti di artisti trentini affermati, sono appunto di Bertoldi, Bonacina, Botteri, Degasperi, Demetz, Fracalossi, Novello, Polo, Seppi e Remo Wolf” (5 novembre 1971).
      Rinaldo Sandri su L’Adige sottolinea come "il proposito sia quello di proporre al pubblico trentino il nome ed i lavori di quegli artisti che usualmente operano al silenzio del loro studio, al di fuori delle strette necessità del mercato”.
      Entrambi i pezzi, seppure con accenti diversi, pongono il problema della posizione dell’artista, e della Galleria, rispetto al mercato.`,
		},
		mainPictures: [
			{ picture: [{ url: nuovaSedeFogolinoMainPic1 }], pictureAlt: 'nuova-sede-fogolino-1' },
			{ picture: [{ url: nuovaSedeFogolinoMainPic2 }], pictureAlt: 'nuova-sede-fogolino-2' },
		],
		catalogoPictures: [],
		recensioniPictures: [
			{ picture: [{ url: nuovaSedeFogolinoRecensioniPic1 }], pictureAlt: 'nuova-sede-fogolino-3' },
			{ picture: [{ url: nuovaSedeFogolinoRecensioniPic2 }], pictureAlt: 'nuova-sede-fogolino-4' },
			{ picture: [{ url: nuovaSedeFogolinoRecensioniPic3 }], pictureAlt: 'nuova-sede-fogolino-5' },
		],
		depliantPictures: [],
	},
	{
		pageUrl: 'ilario-tomasi',
		mainContent: {
			title: '"A quattro mani"',
			startDate: new Date('12/03/1975'),
			endDate: new Date('12/18/1975'),
			content: `Dal 3 al 18 dicembre 1975, presso la Galleria Fogolino, viene presentata la mostra "A quattro mani". Gli artisti sono Mariano Fracalossi e Ilario Tomasi. Una mostra, commenta Manlio Goio nella presentazione, dove "le spille, i monili, i pendagli, le tavolette d'argento nascono nel disegno dalla fervida fantasia di Mariano Fracalossi, ma è Ilario Tomasi che piega la materia preziosa, l'oro, l'argento, nel filo leggero della linea a ripetere motivi floreali o stilizzazioni di piante e animali, o gustosissime forme geometriche (…) è Ilario Tomasi a eseguire nel forno, nella fusione, nella centrifuga, la tavoletta di cera, sulla quale la penna di garbato autentico narratore qual è Mariano Fracalossi" ha inciso le sue storie di favole, segni zodiacali, dame e cavalieri.`,
		},
		mainPictures: [],
		catalogoPictures: [],
		recensioniPictures: [
			{ picture: [{ url: ilarioTomasiRecensioniPic1 }], pictureAlt: 'ilario-tomasi-1' },
		],
		depliantPictures: [
			{ picture: [{ url: ilarioTomasiDepliantPic1 }], pictureAlt: 'ilario-tomasi-2' },
			{ picture: [{ url: ilarioTomasiDepliantPic2 }], pictureAlt: 'ilario-tomasi-3' },
			{ picture: [{ url: ilarioTomasiDepliantPic3 }], pictureAlt: 'ilario-tomasi-4' },
			{ picture: [{ url: ilarioTomasiDepliantPic4 }], pictureAlt: 'ilario-tomasi-5' },
			{ picture: [{ url: ilarioTomasiDepliantPic5 }], pictureAlt: 'ilario-tomasi-6' },
		],
	},
	{
		pageUrl: 'hokusai',
		mainContent: {
			title: 'Hokusai',
			startDate: new Date('04/21/1976'),
			endDate: new Date('05/13/1976'),
			content: `Tra aprile e maggio del 1976 viene presentata alla galleria Fogolino una rassegna con una serie di xilografie originali dell’artista giapponese Katsushika Hokusai (1760-1849). Opere raffinate dove domina un segno sempre elegante, dinamico ma sempre controllato, mai inutile nella capacità di cogliere il dettaglio come parte del tutto.  Fogli pervasi, come nota Mariano Fracalossi nella presentazione, "di un magico stupore per le cose. Sono figurazioni da contemplare per la compiutezza della meditazione, in un vedere che è sentire in concordia ed in armonia con l’infinito. Si tratta dell’antico e magico rito delle immagini attraverso il quale l’uomo si coniuga e si armonizza con il circostante".
      Rinaldo Sandri, su L’Adige, ci ricorda innanzitutto il debito che ebbero con lui gli impressionisti e un artista come Van Gogh, per poi continuare dicendo che sono fogli che ci parlano “di una Natura che non è più esteriore né solamente interiore: assume nell’oggettivo compiersi del tratto artistico una qualità nuova, concreta e reale eppure a noi sconosciuta”.`,
		},
		mainPictures: [],
		catalogoPictures: [
			{ picture: [{ url: hokusaiCatalogoPic1 }], pictureAlt: 'hokusai-1' },
			{ picture: [{ url: hokusaiCatalogoPic2 }], pictureAlt: 'hokusai-2' },
		],
		recensioniPictures: [],
		depliantPictures: [],
	},
	{
		pageUrl: 'marco-berlanda',
		mainContent: {
			title: 'Marco Berlanda',
			startDate: new Date('01/12/1978'),
			endDate: new Date('01/31/1978'),
			content: `Nel gennaio del 1978 Marco Berlanda presenta le sue opere, per la prima volta, alla Galleria Fogolino.
      Rinaldo Sandri, in una breve ma efficace recensione sull’Adige, afferma come, in Marco Berlanda, certe immagini balzino con violenza dalla superficie e riescano a colpire con la loro carica fantastica e dinamica.
      Mariano Fracalossi, nella presentazione, parla di “una creatività priva di problematiche concettuali, nativa, ignorante nei confronti dei ”modelli”, in libertà e naturalezza, arbitraria rispetto al soggetto  che viene assimilato tramite una sorta di gestualità segnica, a volte sgrammaticata, ma in coerenza formale con se stessa, con un suo ordine e equilibrio.”
      Una pratica artistica che sembra evocare, senza troppe mediazioni, le origini dell’arte: 
      “Il fare pittura è legato al segno, il segno è qualcosa di antico come l’uomo, fu una delle sue prime invenzioni. Dal segno nacque la forma che è immagine: essa è circondata dal segno come un canto dall’aria, come un lago dalla terra, come una danza dallo spazio. Canto e danza furono le immagini prime, da loro venne il segno della pittura. La pittura è segno diventato margine per accogliere il colore, Il margine non è confine invalicabile…Accade alle volte all’uomo di ritrovare questa sua antica vocazione. E’ qualcosa che sale dal profondo, prepotente, ed allora di nuovo tutto può ricongiungersi, a condizione di essere oltre il pensare, di essere se stessi in purezza e libertà, a condizione di non ” prevaricare” il proprio segno che ancora una volta ha margine ma non confine.”
      Un discorso all’interno del quale Mariano Fracalossi non dimentica  il ruolo fondamentale del Gruppo di Arti Visuali, luogo di una pratica comunitaria che permette a Marco Berlanda, di ” esprimersi in libertà senza umiliarsi a stereotipie metodologiche.”
      `,
		},
		mainPictures: [],
		catalogoPictures: [
			{ picture: [{ url: marcoBerlandaCatalogoPic1 }], pictureAlt: 'marco-berlanda-1' },
			{ picture: [{ url: marcoBerlandaCatalogoPic2 }], pictureAlt: 'marco-berlanda-2' },
			{ picture: [{ url: marcoBerlandaCatalogoPic3 }], pictureAlt: 'marco-berlanda-3' },
			{ picture: [{ url: marcoBerlandaCatalogoPic4 }], pictureAlt: 'marco-berlanda-4' },
		],
		recensioniPictures: [
			{ picture: [{ url: marcoBerlandaRecensioniPic1 }], pictureAlt: 'marco-berlanda-5' },
		],
		depliantPictures: [],
	},
	{
		pageUrl: 'cirillo-grott',
		mainContent: {
			title: 'Cirillo Grott',
			startDate: new Date('02/01/1973'),
			endDate: new Date('02/27/1973'),
			content: `Nel febbraio del 1973, espone le sue opere alla Fogolino l'artista Cirillo Grott. Scultore e pittore, Cirillo Grott (Guardia di Folgaria, 1937-1990), era legato da un legame di stima e amicizia con Mariano Fracalossi. Un rapporto che si concretizzò con questa mostra personale nel 1973.`,
		},
		mainPictures: [],
		catalogoPictures: [],
		recensioniPictures: [
			{ picture: [{ url: cirilloGrottRecensioniPic1 }], pictureAlt: 'cirillo-grott-1' },
			{ picture: [{ url: cirilloGrottRecensioniPic2 }], pictureAlt: 'cirillo-grott-2' },
			{ picture: [{ url: cirilloGrottRecensioniPic3 }], pictureAlt: 'cirillo-grott-3' },
		],
		depliantPictures: [],
	},
	{
		pageUrl: 'jole-d-agostin',
		mainContent: {
			title: "Jole D'Agostin",
			startDate: new Date('01/05/1983'),
			endDate: new Date('02/01/1983'),
			content: `La riscoperta della sua pittura in Trentino si deve a Mariano Fracalossi, che nel gennaio del 1983 cura alla Fogolino una prima retrospettiva, poi trasferita l’estate successiva al Palazzo Assessorile di Cles. Scrive in quella occasione Mariano: “Io credo che la vicenda della pittura di Jole D’Agostin stia tutta raccolta in questa premessa: una vocazione per il profondo perseguita con coraggio e determinazione, senza compromessi, ricca di intuizioni, libera e generosa”.
            Nata a Cles nel 1921, si diploma a Brera nel 1921. Nel 1942 si stabilisce prima a Novara e, dal 1950, a Milano. È in questi luoghi che si svolge gran parte della sua attività artistica, partecipando, a partire 1946, ad una nutrita serie di collettive e personali. Muore a Milano nel 1981. Nel 2020, nell’imminenza del centenario dalla sua nascita, la Galleria Fogolino espone una selezione di sue opere nella mostra a lei dedicata dal titolo “Alla ricerca del vero”.`,
		},
		mainPictures: [],
		catalogoPictures: [
			{ picture: [{ url: joleDAgostinCatalogoPic1 }], pictureAlt: 'jole-d-agostin-1' },
			{ picture: [{ url: joleDAgostinCatalogoPic2 }], pictureAlt: 'jole-d-agostin-2' },
		],
		recensioniPictures: [
			{ picture: [{ url: joleDAgostinRecensioniPic1 }], pictureAlt: 'jole-d-agostin-3' },
			{ picture: [{ url: joleDAgostinRecensioniPic2 }], pictureAlt: 'jole-d-agostin-4' },
		],
		depliantPictures: [],
	},
	{
		pageUrl: 'pietro-diana',
		mainContent: {
			title: 'Pietro Diana',
			startDate: new Date('11/14/1969'),
			endDate: new Date('12/01/1969'),
			content: `Nel novembre 1969 Pietro Diana (1931-2016) espone presso la Galleria Fogolino.  Sono gli anni in cui insegna all’Accademia di Brera come assistente alle tecniche d’Incisione, di cui dal 1976 sarà titolare.
            Così recita una recensione sull’Adige: “ Se esistessero gli specchi magici  probabilmente vi vedremmo delle immagini simili a quelle che appaiono nelle acqueforti di Pietro Diana. La realtà inanimata, rupi a picco e castelli scavati nella roccia, viene ad assumere un aspetto di vita mostruosa, quasi si trattasse, anziché’ di materia inerte, di quella “massa confusa” di alchimistica memoria nella quale sia possibile trarre forme attive, autonome.”
            Dunque un artista visionario, che ci introduce in un mondo surreale, onirico, popolato di presenze inquietanti. Tutto questo attraverso una raffinata tecnica, che se da un lato propone una illusione di realtà, dall’altra presuppone anche una capacità di vedere dall’esterno questo magma incantato.
            Così, infatti, termina il testo sull’Adige: “Egli costruisce le sue lastre in un silenzio tutto interiore…Allontanarsi dall’obiettivo cui si tende per poterlo scorgere meglio delineato, questo sembra il suo scopo; perché’ i mostri dell’inconscio che risultano, poi, sulla pagina hanno una forza che viene dal distacco dell’artista nel concepirli, al di fuori, si direbbe, dal tempo. Sciabolate di luce intensa che ce li consegnano cristallizzati, momenti di una vicenda oscura e favolosa.”. 
            Un finale dove giustamente si sottolinea la forza espressiva ed evocativa del bianco e nero, in una dinamica tra luce e tenebra, dove il nero si declina in una molteplicità di modi sempre avvolgenti e suggestivi.`,
		},
		mainPictures: [],
		catalogoPictures: [],
		recensioniPictures: [
			{ picture: [{ url: pietroDianaRecensioniPic1 }], pictureAlt: 'pietro-diana-1' },
			{ picture: [{ url: pietroDianaRecensioniPic2 }], pictureAlt: 'pietro-diana-2' },
		],
		depliantPictures: [],
	},
    {
		pageUrl: 'giovanni-battista-piranesi',
		mainContent: {
			title: 'Giovanni Battista Piranesi',
			startDate: new Date('10/14/1976'),
			endDate: new Date('11/02/1976'),
			content: `Nella costante attenzione che la Galleria Fogolino ha dedicato alle tecniche calcografiche, un momento importante è costituito dalla mostra, avvenuta tra il 14 ottobre e il 2 novembre 1976, dedicata a Giovanni Battista Piranesi (1720-1776). Veneziano, di formazione architetto, Piranesi giunge a soli vent’anni a Roma, dove si stabilisce e dedicandosi quasi esclusivamente all’incisione. Appassionato dai monumenti della Roma antica, li raffigura con la tecnica dell’acquaforte in maniera tutt’altro che meramente descrittiva. 
Nel pieghevole Mariano Fracalossi parla “di un modo di incidere disinvolto e libero da schematismi, che procede attraverso successive intuizioni, incalzante nel sovrapporsi dei segni che vengono ad invadere il foglio scandendone il rimo attraverso un guizzare vertiginoso, asimmetrico di linee. È una regia di strutturazione che si vale di moduli triangolari che spesso si risolvono nella cosiddetta “prospettiva d’angolo” di ascendenza teatrale”. E accosta Piranesi a due artisti del ‘900 come Boccioni “che qui viene anticipato per una sorta di esplosione di forze che rimbalzano fra loro e analogamente ad un Sant’Elia della “Città nuova”, per l’audacia di certi rapporti dinamico-spaziali”.
Vertice della ricerca artistica di Piranesi sono le cosiddette “Carceri d’invenzione”. Pubblicate una prima volta nel 1750 e poi riprese e rielaborate nel 1761, sono definite da uno immaginario spazio architettonico, popolato da colossali impalcature, gru, ruote e gallerie, dove si aggirano figurine concitate, come in una sorta di labirinto. Uno spazio visionario scandito da un segno fresco e agile capace di dar forma a chiaroscuri potenti e sempre animati da una vibrazione dinamica.`,
		},
		mainPictures: [],
		catalogoPictures: [
			{ picture: [{ url: giovanniBattistaPiranesiCatalogoPic1 }], pictureAlt: 'giovanni-battista-piranesi-1' },
			{ picture: [{ url: giovanniBattistaPiranesiCatalogoPic2 }], pictureAlt: 'giovanni-battista-piranesi-2' },
		],
		recensioniPictures: [
			{ picture: [{ url: giovanniBattistaPiranesiRecensioniPic1 }], pictureAlt: 'giovanni-battista-piranesi-1' },
			{ picture: [{ url: giovanniBattistaPiranesiRecensioniPic2 }], pictureAlt: 'giovanni-battista-piranesi-2' },
			{ picture: [{ url: giovanniBattistaPiranesiRecensioniPic3 }], pictureAlt: 'giovanni-battista-piranesi-3' },
		],
		depliantPictures: [],
	},
    {
		pageUrl: 'bruno-degasperi',
		mainContent: {
			title: 'Bruno Degasperi',
			content: `La prima personale di Bruno Degasperi (1944-2025) ha luogo alla Galleria Fogolino nell’ottobre 1974. Una mostra che si caratterizza per composizioni dove figure umane sono accostate a rappresentazioni di ingranaggi di vecchie serrature. 
Rinaldo Sandri, nella presentazione, parla di un’indagine sulla forma concreta e astratta degli oggetti, del rinvenire in essi un senso nella materialità che diventa luogo di una ricerca simbolica capace di esprimere una condizione dell’anima. Serrature in ferro e altri congegni meccanici che fanno da contrappunto alla figura umana. Figure contorte, colte in una riflessione su sé stesse, espressione di un disagio esistenziale. “Chiusure mentali”, “Medito”, “Aiutiamoci”, “Devo uscire” sono alcuni dei titoli. Tutto questo appare peraltro risolto da un tratto grafico agile e veloce. “Un segno di rapido effetto” che rende l’immagine viva e pulsante. Dove il colore non ha funzione strutturale ma appare piuttosto come “aereo complemento” del segno, attraverso leggere campiture di blu, azzurro e viola.`,
		},
		mainPictures: [],
		catalogoPictures: [
			{ picture: [{ url: brunoDegasperiCatalogoPic1 }], pictureAlt: 'bruno-degasperi-1' },
			{ picture: [{ url: brunoDegasperiCatalogoPic2 }], pictureAlt: 'bruno-degasperi-2' },
            { picture: [{ url: brunoDegasperiCatalogoPic3 }], pictureAlt: 'bruno-degasperi-3' },
		],
		recensioniPictures: [
			{ picture: [{ url: brunoDegasperiRecensioniPic1 }], pictureAlt: 'bruno-degasperi-1' },
			{ picture: [{ url: brunoDegasperiRecensioniPic2 }], pictureAlt: 'bruno-degasperi-2' },
			{ picture: [{ url: brunoDegasperiRecensioniPic3 }], pictureAlt: 'bruno-degasperi-3' },
		],
		depliantPictures: [],
	},
    {
		pageUrl: 'tamayo',
		mainContent: {
			title: 'Tamayo',
            startDate: new Date('03/09/1977'),
			endDate: new Date('03/29/1977'),
			content: `Ricordiamo che ieri, 25 giugno, era l’anniversario della morte dell’artista messicano Rufino Tamayo (Oaxaca 1899-Città del Messico 1991). Nel marzo del 1977 la Galleria Fogolino gli dedica una mostra personale con una serie di opere di grafica. Sono lavori (litografie e acqueforti) decisamente sperimentali. Tamayo dal 1973 collabora infatti con l’atelier Taller de Grafica Mexicana di Luis e Lea Remba, fondato a Città del Messico nel 1968. Dove Luis Remba, assieme a Tamayo, sviluppa una tecnica di stampa (mixografia) che permette di ottenere effetti coloristici e materici assolutamente originali.
Nel pieghevole della mostra, Mariano Fracalossi afferma che “Rufino Tamayo è l’uomo della fantasia e del colore. Le sue figurazioni trovano i contenuti non tanto sull’accadimento sociale ma piuttosto in qualcosa che pur essendo ad esso partecipe, si scioglie, si libera, nasce dal profondo senza celebrazione, con la sola spinta del fatto cromatico-figurale, in spontaneità creativa, in comunione e simpatia spirituale, vorremmo dire “umanistico” con le antiche e sapienti culture della terra del Messico.  Sono immagini dalle quali fanno capolino i fantasmi del mitico e leggendario “colibrì azzurro”, rimasi per lungo tempo celati nelle pieghe del subcosciente, ancora intatti ancora intatti nella loro antica mitica magia, vivi e attivi perché in essi l’artista si è realizzato in sintonia riuscendo a raccoglierne i lontani ma sempre eterni, infiniti ed attuali messaggi».`,
		},
		mainPictures: [],
		catalogoPictures: [
			{ picture: [{ url: tamayoCatalogoPic1 }], pictureAlt: 'tamayo-1' },
			{ picture: [{ url: tamayoCatalogoPic2 }], pictureAlt: 'tamayo-2' },
		],
		recensioniPictures: [
			{ picture: [{ url: tamayoRecensioniPic1 }], pictureAlt: 'tamayo-1' },
			{ picture: [{ url: tamayoRecensioniPic2 }], pictureAlt: 'tamayo-2' },
		],
		depliantPictures: [],
	},
    {
		pageUrl: 'grafica-ungherese',
		mainContent: {
			title: 'Grafica Ungherese',
            startDate: new Date('02/02/1976'),
			endDate: new Date('02/16/1976'),
			content: ` ...Influenze a cui se ne sommano altre di origine orientale, dando luogo a “musicali grovigli di figure che hanno conosciuto il giardino delle delizie di Hieronjmus d’Olanda ed i suoi pesci volanti, fulgori e rigori rinascimentali che hanno amato il bulino impietoso di Albrecht di Norimberga e che ripropongono velluti e damaschi pervasi di ironia. 
Opere che evocano "una vegetazione rigorosa e reinventata che vive in uno spazio rarefatto senza tempo ma che si può facilmente intravedere nelle finestre delle case affacciate sul Danubio. Così come tutta l’architettura di archi e di ‘vele’ incrociatisi in prospettive perdute ed aggetti di cariatidi.” (Giulio Casella, Grafica Ungherese Contemporanea, 1975).`,
		},
		mainPictures: [],
		catalogoPictures: [
			{ picture: [{ url: graficaUnghereseCatalogoPic1 }], pictureAlt: 'grafica-ungherese-1' },
			{ picture: [{ url: graficaUnghereseCatalogoPic2 }], pictureAlt: 'grafica-ungherese-2' },
		],
		recensioniPictures: [
			{ picture: [{ url: graficaUnghereseRecensioniPic1 }], pictureAlt: 'grafica-ungherese-1' },
			{ picture: [{ url: graficaUnghereseRecensioniPic2 }], pictureAlt: 'grafica-ungherese-2' },
		],
		depliantPictures: [],
	},
    {
		pageUrl: 'stamperia',
		mainContent: {
			title: 'Inaugurazione Stamperia Fogolino Grafica',
			content: `Nel novembre del 1979 viene aperta in Via Esterle a Trento la stamperia Fogolino Grafica. Dell'attività di stampa si occuperà da quel momento Adriano Fracalossi, allora ventenne e studente d'arte al DAMS di Bologna. 
Nell'occasione viene presentata una cartella di incisioni di piccolo formato nelle varie tecniche calcografiche (acquaforte, acquatinta, punta secca, zucchero, impronta e xilografia), eseguite da Remo Wolf, Carlo Bonacina, Lea Botteri, Mariano ed Adriano Fracalossi.`,
		},
		mainPictures: [],
		catalogoPictures: [],
		recensioniPictures: [
			{ picture: [{ url: stamperiaRecensioniPic1 }], pictureAlt: 'stamperia-1' },
			{ picture: [{ url: stamperiaRecensioniPic2 }], pictureAlt: 'stamperia-2' },
		],
		depliantPictures: [],
	},
    {
		pageUrl: 'goya',
		mainContent: {
			title: 'Goya e gli impressionisti',
			content: `L’elenco delle opere esposte, nel pieghevole, si conclude significativamente con due xilografie di Hokusai (1760-1848), quasi a sottolineare l’influenza della grafica giapponese sull’arte occidentale nella seconda metà del secolo.
Così Mariano Fracalossi introduce la mostra: Francisco Goya infranse le regole e le convenzioni. Rinnegò la gabbia d’oro dell’ufficialità. Fu uomo libero, rivolto verso l’avventura del colore e del segno. A cinquant’anni, quasi sordo, si riscoperse nel “fare incisione”, prendendo, disperdendo. E come sempre accade quando la creatività è dirompente, anticipando. Non a caso abbiamo voluto accostare Francisco Goya, nato nel 1746 e morto a Bordeaux nel 1828, a un gruppo di artisti attivi in Francia nell’ambito delle problematiche impressioniste. Accade a volte, che nasca un artista diverso ed allora ciò che era convenuto si scioglie e ciò che in quel momento appare sbagliato, in realtà non è altro che “diverso”.`,
		},
		mainPictures: [],
		catalogoPictures: [
			{ picture: [{ url: goyaCatalogoPic1 }], pictureAlt: 'goya-1' },
			{ picture: [{ url: goyaCatalogoPic2 }], pictureAlt: 'goya-2' },
		],
		recensioniPictures: [
			{ picture: [{ url: goyaRecensioniPic1 }], pictureAlt: 'goya-1' },
			{ picture: [{ url: goyaRecensioniPic2 }], pictureAlt: 'goya-2' },
		],
		depliantPictures: [],
	},
    {
		pageUrl: 'incisioni-originali-spagnole',
		mainContent: {
			title: 'Incisioni Originali Spagnole',
            startDate: new Date('12/01/1976'),
			endDate: new Date('01/01/1977'),
			content: `Il riferimento del sottotitolo è a un manifesto di Antoni Tapies (1923-2012), figura centrale dell’avanguardia spagnola di quegli anni. Un manifesto in cui, secondo una recensione apparsa su L’Alto Adige, “l’esaltazione del materico informale si esprime sempre con un atteggiamento nichilistico verso la storia ed il presente”. Espressione “dello stimolo intellettuale sempre vivo in questi anni in Spagna ed ora apertasi ad un nuovo impegno sociale.” Una mostra che partendo da due artisti ormai storicizzati come Pablo Picasso e Joan Miro’, volge lo sguardo verso l’incisione contemporanea spagnola anche nell’intento di cogliere “il fermento ideale del post franchismo”.  Una rassegna che comprende, tra gli altri, quattro artisti che hanno esposto alla Biennale di Venezia, come Raflos Casamada, Lucio Munoz, Jose Betram Guinovart, Eusebio Sempere. Accanto ad essi segnaliamo la presenza di opere di Jose’ Ortega (1921-1990) e del gruppo “Equipo Realidad”  formato da Joan Cardells (1948-2019) e Jorge Ballester (1941-2014).`,
		},
		mainPictures: [],
		catalogoPictures: [
			{ picture: [{ url: incisioniOriginaliSpagnoleCatalogoPic1 }], pictureAlt: 'incisioni-originali-spagnole-1' },
			{ picture: [{ url: incisioniOriginaliSpagnoleCatalogoPic2 }], pictureAlt: 'incisioni-originali-spagnole-2' },
		],
		recensioniPictures: [],
		depliantPictures: [],
	},

];

export const skillPages = [
	{
		pageUrl: 'grafica-incisa',
		mainContent: {
			title: 'Grafica Incisa',
			content: `Strettamente connessa al disegno, diventa a partire dagli anni ’60, laboratorio di sperimentazioni, con un uso spesso combinato della ceramolle, acquaforte, acquatinta. Dagli anni 70 troviamo matrici in parte ritagliate per creare effetti a secco ed inserti di carte colorate precedentemente incise e stampate, volte ad inserire campiture colorate. Dalla fine degli anni ’70 Mariano Fracalossi introduce la pratica dell’incisione a colore con diverse matrici.`,
		},
		galleryPictures: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
	},
	{
		pageUrl: 'pittura',
		mainContent: {
			title: 'Pittura',
			content: `La pittura di Mariano Fracalossi è caratterizzata dall’uso della tempera, in particolare delle terre. Un uso che passa attraverso una pennellata agile in cui il segno si combina con la ricerca di effetti materici. A partire dagli anni ’60 troviamo anche un uso del caseato per ottenere una superficie con una consistenza simile a quella del muro. Una superficie su cui interviene anche con il segno della matita nera, creando una sorta di graffito.`,
		},
		galleryPictures: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
	},
	{
		pageUrl: 'disegno',
		mainContent: {
			title: 'Disegno',
			content: `Il segno appare centrale nella ricerca artistica di Mariano Fracalossi. Una pratica che si realizza sia nei disegni dal vero che negli schizzi, attraverso l’uso della matita (anche su superfici dipinte, con il colore ancora fresco), dei pennarelli (a partire dagli anni ’60, con il flo-master) o con la biro. Per quanto riguarda i disegni preparatori delle pitture, prevale l’uso del carboncino e del pennarello negli spolveri.`,
		},
		galleryPictures: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
	},
	{
		pageUrl: 'scenografia',
		mainContent: {
			title: 'Scenografia',
			content: `La scenografia rientra nella tradizione di famiglia in quanto già praticata dal padre di Mariano, Attilio Fracalossi. Mariano ne fa una ricca esperienza realizzando le scene per i lavori teatrali di Marcello Voltolini, nei primi anni del secondo dopoguerra nell’Oratorio di San Pietro, a Trento. Una ripresa avviene negli anni ’70, sempre in sodalizio con Marcello Voltolini, presso il teatro San Marco, a Trento.`,
		},
		galleryPictures: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
	},
	{
		pageUrl: 'gioielli',
		mainContent: {
			title: 'Gioielli',
			content: `L’ideazione di pezzi unici in oreficeria ha luogo a partire dalla mostra “A quattro mani” (1975) dove Mariano collabora con Ilario Tomasi, orafo e titolare di un laboratorio di oreficeria, a Trento. La produzione, com’è testimoniato da una notevole quantità di schizzi e modelli, continua fino alla fine degli anni ’90 con l’esecuzione affidata ad altri orafi. Nei gioielli ritroviamo, sotto altre forme, motivi e caratteristiche della pittura, con una predominanza della linearità, di motivi a griglia, e di effetti materici.`,
		},
		galleryPictures: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
	},
	{
		pageUrl: 'vetrate',
		mainContent: {
			title: 'Vetrate',
			content: `Le vetrate sono un ulteriore esempio della versatilità dello stile di Mariano Fracalossi, in cui le possibilità narrative della linea stilizzata, con la sua capacità di sintesi, bene si adatta alle possibilità strutturali delle vetrate. L’ esempio più rilevante è quello della chiesa della Madonna della Neve a Fondo Grande (Folgaria), alla base degli impianti di sci.`,
		},
		galleryPictures: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
	},
	{
		pageUrl: 'mosaico',
		mainContent: {
			title: 'Mosaico',
			content: `L’approccio di Mariano Fracalossi a questa tecnica avviene, negli anni ’60, con la collaborazione di Gino Novello. Con Novello vince due concorsi per opere pubbliche, rispettivamente Stresa e San Vito sullo Jonio. Una seconda fase si ha a partire dagli anni ’80, con la realizzazione del mosaico del Palazzo della Provincia a Trento, in cui si avvale della collaborazione di una ditta specializzata di Verona.
      Con Gino Novello, partecipa direttamente alla realizzazione, mentre nei lavori più recenti Mariano parte da bozzetti che poi traduce col carboncino in scala 1:1. Sono poi realizzati da una ditta specializzata, in un rapporto di continua collaborazione e scambio di opinioni sulle possibili soluzioni per il colore e l’uso dei materiali.`,
		},
		galleryPictures: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
	},
	{
		pageUrl: 'smalto',
		mainContent: {
			title: 'Smalto',
			content: `Anche nello smalto, Mariano Fracalossi crea partendo da specifici bozzetti a biro e matite colorate, che poi vengono realizzati nell’antica tecnica del cloisonné da Marcello Baldassarri. Tra i vari esempi ricordiamo un reliquiario per il Beato Giovanni Nepomuceno de Tschiderer, realizzato nel 1995, ed attualmente esposto in Vaticano, e la Via Crucis nella chiesa di Santo Stefano a Vermiglio.`,
		},
		galleryPictures: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
	},
	{
		pageUrl: 'affresco',
		mainContent: {
			title: 'Affresco',
			content: `Strettamente collegata al suo modo di intendere la pittura, la tecnica dell’affresco, malgrado i pochi esempi, è un riferimento costante per Mariano Fracalossi, al punto che cerca di ricreare anche nella pittura, su altri supporti, le condizioni e l’effetto della superficie muraria.`,
		},
		galleryPictures: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
	},
	{
		pageUrl: 'varia',
		mainContent: {
			title: 'Varia',
			content: `Mariano Fracalossi è una figura che si muove nella pratica artistica a tutto campo, dalla pittura alla grafica, dalle vetrate al mosaico per citare solo alcune categorie. A partire dalla sua attività di insegnante per arrivare alle collaborazioni con altri artisti o artigiani, il suo è un fare in cui appare evidente la sua disponibilità a condividere e mettere in gioco la sua idea dell'arte. Un muoversi a tutto campo nel mondo della cultura, che, a partire dagli anni ‘60, lo porta a impegnarsi in prima persona, prima nel Sindacato Italiano Artisti Belle Arti, nell’Ucai, per poi fondare nel 1966 la Galleria Fogolino, ma anche realtà associative come il gruppo Studio Arti Visuali e La Cerchia. 
      Un bisogno di partecipazione al dibattito culturale di cui i suoi interventi scritti sono ulteriore, e ancora attuale, testimonianza.`,
		},
		galleryPictures: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
	},
];
