import { Main, MainSection, NavBar, OpenableSection, Page, PictureGrid } from 'components';
import { Helmet } from 'react-helmet-async';
import { eventPages } from '../../api';

const GiovanniBattistaPiranesiPage = () => {
	const pageData = eventPages.find((eventPage) => eventPage.pageUrl === 'giovanni-battista-piranesi');
	return (
		<Page>
			<Helmet>
				<title>Giovanni Battista Piranesi</title>
			</Helmet>
			<NavBar />
			<Main>
				<MainSection
					data={pageData?.mainContent}
					children={<PictureGrid data={pageData?.mainPictures} />}
				/>
				{pageData?.catalogoPictures.length ? (
					<OpenableSection title="catalogo" data={pageData.catalogoPictures} />
				) : null}
				{pageData?.recensioniPictures.length ? (
					<OpenableSection title="recensioni" data={pageData.recensioniPictures} />
				) : null}
				{pageData?.depliantPictures.length ? (
					<OpenableSection title="depliants" data={pageData?.depliantPictures} />
				) : null}
			</Main>
		</Page>
	);
};

export default GiovanniBattistaPiranesiPage;
