import pictureCardPlaceholder from 'assets/images/picture-card-placeholder.png';
import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';
import { ArticleData } from 'types/data.types';
import styles from './Article.module.scss';

type Props = {
	data?: ArticleData;
	skeleton?: boolean;
};

const Article = ({ data, skeleton = false }: Props) => {
	const date = `${dayjs(data?.date).format('DD/MM/YYYY')}`;
	return (
		<div className={styles.article}>
			{!skeleton ? (
				<img
					className={styles.picture}
					src={(data?.picture && data.picture[0].url) || pictureCardPlaceholder}
					alt={data?.pictureAlt}
				/>
			) : (
				<Skeleton containerClassName={styles.picture} height={612} />
			)}
			<div className={styles.header}>
				<h3 className={styles.title}>{!skeleton ? data?.title : <Skeleton />}</h3>
				<p className={styles.author}>{!skeleton ? data?.author : <Skeleton />}</p>
				<p className={styles.date}>{!skeleton ? date : <Skeleton />}</p>
			</div>
			<div dangerouslySetInnerHTML={{ __html: data?.html || '' }} />
		</div>
	);
};

export default Article;
