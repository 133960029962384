import styles from './SocialNetwork.module.scss';
import instagramIcon from 'assets/icons/instagram-black.png';
import facebookIcon from 'assets/icons/facebook-black.png';
import mailIcon from 'assets/icons/mail-black.png';

type Props = {
	type: string;
	link?: string;
};

const data: { [key: string]: { icon: string; link: string } } = {
	instagram: {
		icon: instagramIcon,
		link: 'https://www.instagram.com/',
	},
	facebook: {
		icon: facebookIcon,
		link: 'https://www.facebook.com/',
	},
	mail: {
		icon: mailIcon,
		link: 'mailto:',
	},
};

const SocialNetwork = ({ type, link }: Props) => {
	return (
		<a className={styles.socialNetwork} href={`${data[type].link}${link}`}>
			<img className={styles.icon} src={data[type].icon} alt={type} />
			<p className={styles.link}>{type === 'instagram' ? `@${link}` : link}</p>
		</a>
	);
};

export default SocialNetwork;
