import { useNavigate } from 'react-router-dom';
import styles from './Biography.module.scss';

type Props = {
	skills?: string[];
	data?: string;
	skeleton?: boolean;
};

const Biography = ({ skills, data, skeleton = false }: Props) => {
	const navigate = useNavigate();
	const handleSkillClick = (skill: string, e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		navigate(`/mariano-fracalossi/${skill.replace(' ', '-')}`);
	};

	return (
		<section className={styles.container}>
			<div className={styles.biography}>
				<p>{data}</p>
			</div>
			{skills && (
				<div className={styles.skillsList}>
					{skills.map((skill: string, index: number) => (
						<a key={index} href={skill} onClick={(e) => handleSkillClick(skill, e)}>
							{skill}
						</a>
					))}
				</div>
			)}
		</section>
	);
};

export default Biography;
