import React from 'react';
import styles from './Page.module.scss';

type Props = {
	children: React.ReactNode;
};

const Page: React.FC<Props> = ({ children }) => {
	return <div className={styles.layout}>{children}</div>;
};

export default Page;
