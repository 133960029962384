import styles from './ArticleList.module.scss';
import { ArticleCard } from 'components';
import { ArticleData } from 'types/data.types';

type Props = {
	data?: ArticleData[];
	skeleton?: boolean;
};

const ArticleList = ({ data, skeleton = false }: Props) => {
	return (
		<div className={styles.container}>
			{!skeleton
				? data?.map((article: ArticleData, index: number) => (
						<ArticleCard data={article} key={index} />
				  ))
				: new Array(4).fill('').map((_, index: number) => <ArticleCard isSkeleton key={index} />)}
		</div>
	);
};

export default ArticleList;
