import { ChronologyItem } from 'components';
import React, { useState } from 'react';
import { EventData } from 'types/data.types';
import styles from './Chronology.module.scss';

type Props = {
	data?: EventData[];
	dates?: string[];
	skeleton?: boolean;
};

const Chronology = ({ data, dates, skeleton = false }: Props) => {
	const [selectedDate, setSelectedDate] = useState<string>((dates && dates[0]) || '');
	const eventRefs = data?.map(() => React.createRef<HTMLDivElement>());
	const eventListRef = React.createRef<HTMLDivElement>();

	const handleDateClick = (date: string, _e: React.MouseEvent<HTMLAnchorElement>) => {
		setSelectedDate(() => date);
	};

	const handleScroll = () => {
		const eventList = eventListRef && (eventListRef.current as HTMLDivElement);
		const scrollPos = eventList.scrollTop + eventList.offsetTop;

		const eventOnTop = eventRefs?.find(
			(eventRef) => (eventRef && (eventRef.current as HTMLDivElement)).offsetTop > scrollPos
		);

		const date = eventOnTop && (eventOnTop.current as HTMLDivElement).id;
		date && setSelectedDate(() => date);
	};

	return (
		<section className={styles.container}>
			<div className={styles.eventsList} ref={eventListRef} onScroll={handleScroll}>
				{!skeleton
					? data?.map((event: EventData, index: number) => (
							<ChronologyItem
								event={event}
								key={event.title}
								itemRef={eventRefs && eventRefs[index]}
								isSkeleton={skeleton}
							/>
					  ))
					: new Array(5).fill('').map((_, index) => <ChronologyItem isSkeleton key={index} />)}
			</div>

			{dates && (
				<div className={styles.datesList}>
					{dates.map((date: string) => (
						<a
							className={selectedDate === date ? styles.active : undefined}
							key={date}
							href={`#${date}`}
							onClick={(e) => handleDateClick(date, e)}
						>
							{date}
						</a>
					))}
				</div>
			)}
		</section>
	);
};

export default Chronology;
