import styles from './Header.module.scss';
import headerPlaceholder from 'assets/images/header-placeholder.png';
import { SocialNetwork } from 'components';

type Props = {
	description?: string;
	titleSrc?: string;
	horizontalTitleSrc?: string;
	backgroundSrc: string;
};
const Header = ({ titleSrc, horizontalTitleSrc, description, backgroundSrc }: Props) => {
	return (
		<header
			className={`${styles.header} ${titleSrc && styles.blur}`}
			style={{
				background: `no-repeat center center / cover url(${backgroundSrc || headerPlaceholder})`,
			}}
		>
			<div className={styles.content}>
				{titleSrc && <img className={styles.titlePicture} src={titleSrc} alt="il progetto" />}
				{horizontalTitleSrc && (
					<img
						className={styles.horizontalTitlePicture}
						src={horizontalTitleSrc}
						alt="il progetto"
					/>
				)}

				{description && <p className={styles.description}>{description}</p>}

				{!titleSrc && !horizontalTitleSrc && !description && (
					<div className={styles.socialNetworks}>
						<SocialNetwork type="mail" link="galleria.fogolino@gmail.com" />
						<SocialNetwork type="facebook" link="Archiviofogolino" />
						<SocialNetwork type="instagram" link="archiviofogolino" />
					</div>
				)}
			</div>
		</header>
	);
};

export default Header;
