import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';
import { PageData } from 'types/data.types';
import styles from './MainSection.module.scss';

type Props = {
	data?: PageData;
	children?: React.ReactNode;
	isSkeleton?: boolean;
};

const MainSection = ({ data, children, isSkeleton = false }: Props) => {
	const startDate = data?.startDate ? `${dayjs(data?.startDate).format('DD/MM/YYYY')}` : '';
	const endDate = data?.endDate ? ` - ${dayjs(data?.endDate).format('DD/MM/YYYY')}` : '';
	return (
		<section className={styles.section}>
			<h2 className={styles.title}>{!isSkeleton ? data?.title : <Skeleton />}</h2>
			<p className={styles.date}>{!isSkeleton ? `${startDate}${endDate}` : <Skeleton />}</p>
			<p className={styles.content}>{!isSkeleton ? data?.content : <Skeleton count={5} />}</p>
			{children}
		</section>
	);
};

export default MainSection;
