import { NavLink } from 'react-router-dom';
import styles from './DesktopMenu.module.scss';
// import loupeIcon from 'assets/icons/loupe.png';

type Props = {
	menuContent: { title: string; path: string }[];
};

const DesktopMenu = ({ menuContent }: Props) => {
	return (
		<nav className={styles.navbar}>
			{menuContent.map((menu, index: number) => (
				<NavLink
					to={menu.path}
					className={(navData) =>
						navData.isActive ? `${styles.navlink} ${styles.active}` : styles.navlink
					}
					key={index}
				>
					<p className={styles.navlinkTitle}>{menu.title}</p>
				</NavLink>
			))}
			{/* <img className={styles.navbarIcon} src={loupeIcon} alt="search" /> */}
		</nav>
	);
};

export default DesktopMenu;
