import styles from './Catalog.module.scss';

type Props = {
	catalogTitle: string;
	catalogUrl: string;
	pictureAlt: string;
	pictureSrc: string;
};

const Catalog = ({ catalogTitle, catalogUrl, pictureAlt, pictureSrc }: Props) => {
	return (
		<a className={styles.link} href={catalogUrl} target="_blank" rel="noreferrer">
			<img className={styles.picture} src={pictureSrc} alt={pictureAlt} />
			<div>
				<h4>{catalogTitle}</h4>
				<p>
					Mariano Fracalossi (1923 - 2004)
					<br />
					Trento, Palazzo Trentini
					<br />
					23 gennaio 2015 - 7 marzo 2015
				</p>
			</div>
		</a>
	);
};

export default Catalog;
