import { NavLink } from 'react-router-dom';
import styles from './NavBar.module.scss';
import DesktopMenu from './DesktopMenu/DesktopMenu';
import MobileMenu from './MobileMenu/MobileMenu';
import archivioLogo from 'assets/icons/logo-archivio-compact.svg';

const pages = [
	{
		title: 'IL PROGETTO',
		path: '/il-progetto',
	},
	{
		title: 'GALLERIA FOGOLINO',
		path: '/galleria-fogolino',
	},
	{
		title: 'MARIANO FRACALOSSI',
		path: '/mariano-fracalossi',
	},
	{
		title: 'FOGOLINO FOCUS',
		path: '/fogolino-focus',
	},
	{
		title: 'BLOG',
		path: '/blog',
	},
	{ title: 'CONTATTI', path: '/contatti' },
];

const NavBar = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.menu}>
				<NavLink to="/">
					<img className={styles.logo} src={archivioLogo} alt="archivio-fogolino" />
				</NavLink>
				<DesktopMenu menuContent={pages} />
				<MobileMenu menuContent={pages} />
			</div>
		</div>
	);
};

export default NavBar;
