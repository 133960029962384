import pictureCardPlaceholder from 'assets/images/picture-card-placeholder.png';
import { useCallback, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { PictureData } from 'types/data.types';
import styles from './PictureCard.module.scss';

type Props = {
	width?: number;
	data?: PictureData;
	isSkeleton?: boolean;
};

const PictureCard = ({ width, data, isSkeleton = false }: Props) => {
	const [isZoomed, setIsZoomed] = useState(false);

	const handleClick = () => setIsZoomed(!isZoomed);

	const handleZoomChange = useCallback((shouldZoom: boolean) => {
		setIsZoomed(shouldZoom);
	}, []);

	return (
		<ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
			<div className={styles.pictureCard} onClick={handleClick}>
				{!isSkeleton ? (
					<img
						className={styles.picture}
						src={(data?.picture && data.picture[0].url) || pictureCardPlaceholder}
						alt={data?.pictureAlt}
					/>
				) : (
					<Skeleton height={'30vh'} width={width || '100%'} containerClassName={styles.picture} />
				)}
				<div className={styles.hover}>
					<p className={styles.title}>{!isSkeleton ? data?.title : <Skeleton />}</p>
					<p className={styles.desc}>{!isSkeleton ? data?.description : <Skeleton />}</p>
				</div>
			</div>
		</ControlledZoom>
	);
};

export default PictureCard;
