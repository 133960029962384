import { PictureCard } from 'components';
import { PictureData } from 'types/data.types';
import styles from './PictureGrid.module.scss';

type Props = {
	data?: PictureData[];
	isSkeleton?: boolean;
	marginBottom?: number;
};

const PictureGrid = ({ data, marginBottom, isSkeleton = false }: Props) => {
	return (
		<div className={styles.container} style={{ marginBottom: `${marginBottom}px` }}>
			{!isSkeleton
				? data?.map((picture: PictureData, index: number) => (
						<PictureCard data={picture} key={index} />
				  ))
				: new Array(4)
						.fill('')
						.map((_, index: number) => <PictureCard width={534.5} isSkeleton key={index} />)}
		</div>
	);
};

export default PictureGrid;
