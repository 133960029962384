import downArrow from 'assets/icons/down-arrow.png';
import rightArrow from 'assets/icons/right-arrow.png';
import { PictureGrid } from 'components';
import { useState } from 'react';
import { PictureData } from 'types/data.types';
import styles from './OpenableSection.module.scss';

type Props = {
	data?: PictureData[];
	isSkeleton?: boolean;
	title: string;
};

const OpenableSection = ({ data, title, isSkeleton = false }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const handleClick = () => {
		setIsOpen(!isOpen);
	};
	return (
		<section className={styles.section}>
			<div className={styles.header} onClick={handleClick}>
				<img
					src={isOpen ? downArrow : rightArrow}
					className={styles.button}
					alt={isOpen ? 'close' : 'open'}
				/>
				<h2 className={styles.title}>{title}</h2>
			</div>
			{isOpen && <PictureGrid data={data} marginBottom={50} />}
		</section>
	);
};

export default OpenableSection;
