import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import menuIcon from 'assets/icons/menu-white.png';
import crossIcon from 'assets/icons/cross-white.png';
import styles from './MobileMenu.module.scss';

type Props = {
	menuContent: { title: string; path: string }[];
};

const MobileMenu = ({ menuContent }: Props) => {
	const [openMenu, setOpenMenu] = useState(false);
	const handleMenu = () => setOpenMenu((prev) => !prev);
	return (
		<div className={styles.navbar}>
			<img className={styles.menuIcon} src={menuIcon} onClick={handleMenu} alt="open-menu" />
			<nav className={openMenu ? `${styles.menu} ${styles.active}` : `${styles.menu}`}>
				<img className={styles.crossIcon} src={crossIcon} onClick={handleMenu} alt="close-menu" />
				<ul className={styles.linkGroup}>
					{menuContent.map((menu, index: number) => {
						return (
							<li key={index}>
								<NavLink
									to={menu.path}
									className={(navData) =>
										navData.isActive ? `${styles.navlink} ${styles.active}` : styles.navlink
									}
								>
									<p className={styles.navlinkTitle}>{menu.title}</p>
								</NavLink>
							</li>
						);
					})}
				</ul>
			</nav>
		</div>
	);
};

export default MobileMenu;
