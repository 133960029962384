import { PictureCard } from 'components';
import { PictureData } from 'types/data.types';
import styles from './GalleryColumn.module.scss';

type Props = {
	type: 'wide' | 'thin';
	pictures: PictureData[];
	isSkeleton?: boolean;
};

const GalleryColumn = ({ type, pictures, isSkeleton }: Props) => {
	return (
		<div className={`${styles.column} ${type === 'wide' ? styles.wide : styles.thin}`}>
			{!isSkeleton
				? pictures.map((columnPic: PictureData, index: number) => (
						<PictureCard data={columnPic} key={index} />
				  ))
				: new Array(3).fill('').map((_, index) => <PictureCard isSkeleton key={index} />)}
		</div>
	);
};

export default GalleryColumn;
