import { useState } from 'react';
import styles from './Carousel.module.scss';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { PictureCard } from 'components';
import { PictureData } from 'types/data.types';
import leftArrow from 'assets/icons/left-arrow.png';
import rightArrow from 'assets/icons/right-arrow.png';

SwiperCore.use([Navigation, Pagination]);

type Props = {
	pictures?: PictureData[];
	skeleton?: boolean;
};

const Carousel = ({ pictures, skeleton = false }: Props) => {
	const [prevEl, setPrevEl] = useState<HTMLImageElement | null>(null);
	const [nextEl, setNextEl] = useState<HTMLImageElement | null>(null);
	return (
		<section className={styles.container}>
			<img
				ref={(node) => setPrevEl(node)}
				src={leftArrow}
				className={styles.button}
				alt="prev"
			></img>
			<Swiper
				navigation={{ prevEl, nextEl }}
				pagination={{ clickable: false }}
				spaceBetween={20}
				slidesPerView={2}
				loop
			>
				{!skeleton
					? [ ...(pictures?.map((carouselPic: PictureData, index: number) => (
								<SwiperSlide className={styles.slide} key={index}>
									<PictureCard data={carouselPic} key={index} />
								</SwiperSlide>
							)) || []),
					  ]
					: new Array(2).fill('').map((_, index) => (
							<SwiperSlide className={styles.slide} key={index}>
								<PictureCard isSkeleton />
							</SwiperSlide>
					  ))}
			</Swiper>
			<img
				ref={(node) => setNextEl(node)}
				src={rightArrow}
				className={styles.button}
				alt="next"
			></img>
		</section>
	);
};

export default Carousel;
