import GiovanniBattistaPiranesiPage from 'pages/EventPages/GiovanniBattistaPiranesiPage';
import * as React from 'react';
import { lazy, Suspense } from 'react';
import ReactGA from 'react-ga4';
import { Route, Routes, useLocation } from 'react-router-dom';

const AffrescoPage = lazy(() => import('pages/SkillPages/AffrescoPage'));
const AlbertoGraziadeiPage = lazy(() => import('pages/EventPages/AlbertoGraziadeiPage'));
const ArteSacraPage = lazy(() => import('pages/EventPages/ArteSacraPage'));
const ArticlePage = lazy(() => import('pages/ArticlePage/ArticlePage'));
const BlogPage = lazy(() => import('pages/BlogPage/BlogPage'));
const BrunoDegasperiPage = lazy(() => import('pages/EventPages/BrunoDegasperiPage'));
const CarloBonacinaPage = lazy(() => import('pages/ArtistPages/CarloBonacinaPage'));
const CesarinaSeppiPage = lazy(() => import('pages/ArtistPages/CesarinaSeppiPage'));
const ContattiPage = lazy(() => import('pages/ContattiPage/ContattiPage'));
const DisegnoPage = lazy(() => import('pages/SkillPages/DisegnoPage'));
const EventCarloBonacinaPage = lazy(() => import('pages/EventPages/CarloBonacinaPage'));
const EventCirilloGrottPage = lazy(() => import('pages/EventPages/CirilloGrottPage'));
const EventJoleDAgostinPage = lazy(() => import('pages/EventPages/JoleDAgostinPage'));
const EventMarcoBerlandaPage = lazy(() => import('pages/EventPages/MarcoBerlandaPage'));
const EventMarcoBertoldiPage = lazy(() => import('pages/EventPages/MarcoBertoldiPage'));
const EventMarianoFracalossiPage = lazy(() => import('pages/EventPages/MarianoFracalossiPage'));
const FogolinoFocusPage = lazy(() => import('pages/FogolinoFocusPage/FogolinoFocusPage'));
const GalleriaFogolinoPage = lazy(() => import('pages/GalleriaFogolinoPage/GalleriaFogolinoPage'));
const GinoNovelloPage = lazy(() => import('pages/EventPages/GinoNovelloPage'));
const GioielliPage = lazy(() => import('pages/SkillPages/GioielliPage'));
const GoyaPage = lazy(() => import('pages/EventPages/GoyaPage'));
const GraficaIncisaPage = lazy(() => import('pages/SkillPages/GraficaIncisaPage'));
const GraficaUngheresePage = lazy(() => import('pages/EventPages/GraficaUngheresePage'));
const HokusaiPage = lazy(() => import('pages/EventPages/HokusaiPage'));
const HomePage = lazy(() => import('pages/HomePage/HomePage'));
const IlarioTomasiPage = lazy(() => import('pages/EventPages/IlarioTomasiPage'));
const InaugurationPage = lazy(() => import('pages/EventPages/InaugurationPage'));
const InaugurazioneStamperiaFogolinoGraficaPage = lazy(() => import('pages/EventPages/InaugurazioneStamperiaFogolinoGraficaPage'));
const IncisioniOriginaliSpagnolePage = lazy(() => import('pages/EventPages/IncisioniOriginaliSpagnolePage'));
const JoleDAgostinPage = lazy(() => import('pages/ArtistPages/JoleDAgostinPage'));
const LeaBotteriPage = lazy(() => import('pages/ArtistPages/LeaBotteriPage'));
const LuigiDegasperiPage = lazy(() => import('pages/ArtistPages/LuigiDegasperiPage'));
const MarcoBerlandaPage = lazy(() => import('pages/ArtistPages/MarcoBerlandaPage'));
const MarcoBertoldiPage = lazy(() => import('pages/ArtistPages/MarcoBertoldiPage'));
const MarianoFracalossiPage = lazy(
	() => import('pages/MarianoFracalossiPage/MarianoFracalossiPage')
);
const MartinDemetzPage = lazy(() => import('pages/EventPages/MartinDemetzPage'));
const MassimoRadicioniPage = lazy(() => import('pages/EventPages/MassimoRadicioniPage'));
const MosaicoPage = lazy(() => import('pages/SkillPages/MosaicoPage'));
const NuovaSedeFogolinoPage = lazy(() => import('pages/EventPages/NuovaSedeFogolinoPage'));
const PietroDianaPage = lazy(() => import('pages/EventPages/PietroDianaPage'));
const PietroParigiPage = lazy(() => import('pages/EventPages/PietroParigiPage'));
const PitturaPage = lazy(() => import('pages/SkillPages/PitturaPage'));
const ProgettoPage = lazy(() => import('pages/ProgettoPage/ProgettoPage'));
const RemoWolfPage = lazy(() => import('pages/ArtistPages/RemoWolfPage'));
const ScenografiaPage = lazy(() => import('pages/SkillPages/ScenografiaPage'));
const SmaltoPage = lazy(() => import('pages/SkillPages/SmaltoPage'));
const StamperiaPage = lazy(() => import('pages/EventPages/StamperiaPage'));
const TamayoPage = lazy(() => import('pages/EventPages/TamayoPage'));
const VariaPage = lazy(() => import('pages/SkillPages/VariaPage'));
const VetratePage = lazy(() => import('pages/SkillPages/VetratePage'));

const TRACKING_ID = 'G-B37P87WB69';

ReactGA.initialize(TRACKING_ID);

const AppRoutes = () => {
	let location = useLocation();

	console.log(location);
	React.useEffect(() => {
		ReactGA.ga('send', 'pageview');
	}, [location]);

	return (
		<Suspense fallback={<></>}>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/articles/:id" element={<ArticlePage />} />
				<Route path="/blog" element={<BlogPage />} />
				<Route path="/contatti" element={<ContattiPage />} />
				<Route path="/fogolino-focus" element={<FogolinoFocusPage />} />
				<Route path="/fogolino-focus/carlo-bonacina" element={<CarloBonacinaPage />} />
				<Route path="/fogolino-focus/cesarina-seppi" element={<CesarinaSeppiPage />} />
				<Route path="/fogolino-focus/jole-d-agostin" element={<JoleDAgostinPage />} />
				<Route path="/fogolino-focus/lea-botteri" element={<LeaBotteriPage />} />
				<Route path="/fogolino-focus/luigi-degasperi" element={<LuigiDegasperiPage />} />
                <Route path="/fogolino-focus/marco-berlanda" element={<MarcoBerlandaPage />} />
				<Route path="/fogolino-focus/marco-bertoldi" element={<MarcoBertoldiPage />} />
				<Route path="/fogolino-focus/remo-wolf" element={<RemoWolfPage />} />
				<Route path="/galleria-fogolino" element={<GalleriaFogolinoPage />} />
				<Route path="/galleria-fogolino/alberto-graziadei" element={<AlbertoGraziadeiPage />} />
				<Route path="/galleria-fogolino/arte-sacra" element={<ArteSacraPage />} />
                <Route path="/galleria-fogolino/bruno-degasperi" element={<BrunoDegasperiPage />} />
				<Route path="/galleria-fogolino/carlo-bonacina" element={<EventCarloBonacinaPage />} />
				<Route path="/galleria-fogolino/cirillo-grott" element={<EventCirilloGrottPage />} />
				<Route path="/galleria-fogolino/gino-novello" element={<GinoNovelloPage />} />
                <Route path="/galleria-fogolino/giovanni-battista-piranesi" element={<GiovanniBattistaPiranesiPage />} />
                <Route path="/galleria-fogolino/goya" element={<GoyaPage />} />
                <Route path="/galleria-fogolino/grafica-ungherese" element={<GraficaUngheresePage />} />
                <Route path="/galleria-fogolino/hokusai" element={<HokusaiPage />} />
				<Route path="/galleria-fogolino/ilario-tomasi" element={<IlarioTomasiPage />} />
				<Route path="/galleria-fogolino/inaugurazione" element={<InaugurationPage />} />
                <Route path="/galleria-fogolino/inaugurazione-stamperia-fogolino-grafica" element={<InaugurazioneStamperiaFogolinoGraficaPage />} />
                <Route path="/galleria-fogolino/incisioni-originali-spagnole" element={<IncisioniOriginaliSpagnolePage />} />
                <Route path="/galleria-fogolino/jole-d-agostin" element={<EventJoleDAgostinPage />} />
				<Route path="/galleria-fogolino/marco-berlanda" element={<EventMarcoBerlandaPage />} />
				<Route path="/galleria-fogolino/marco-bertoldi" element={<EventMarcoBertoldiPage />} />
				<Route
					path="/galleria-fogolino/mariano-fracalossi"
					element={<EventMarianoFracalossiPage />}
				/>
				<Route path="/galleria-fogolino/martin-demetz" element={<MartinDemetzPage />} />
				<Route path="/galleria-fogolino/massimo-radicioni" element={<MassimoRadicioniPage />} />
				<Route path="/galleria-fogolino/nuova-sede-fogolino" element={<NuovaSedeFogolinoPage />} />
                <Route path="/galleria-fogolino/pietro-diana" element={<PietroDianaPage />} />
				<Route path="/galleria-fogolino/pietro-parigi" element={<PietroParigiPage />} />
                <Route path="/galleria-fogolino/stamperia" element={<StamperiaPage />} />
                <Route path="/galleria-fogolino/tamayo" element={<TamayoPage />} />
				<Route path="/mariano-fracalossi" element={<MarianoFracalossiPage />} />
				<Route path="/mariano-fracalossi/affresco" element={<AffrescoPage />} />
				<Route path="/mariano-fracalossi/disegno" element={<DisegnoPage />} />
				<Route path="/mariano-fracalossi/gioielli" element={<GioielliPage />} />
				<Route path="/mariano-fracalossi/grafica-incisa" element={<GraficaIncisaPage />} />
				<Route path="/mariano-fracalossi/mosaico" element={<MosaicoPage />} />
				<Route path="/mariano-fracalossi/pittura" element={<PitturaPage />} />
				<Route path="/mariano-fracalossi/scenografia" element={<ScenografiaPage />} />
				<Route path="/mariano-fracalossi/smalto" element={<SmaltoPage />} />
				<Route path="/mariano-fracalossi/varia" element={<VariaPage />} />
				<Route path="/mariano-fracalossi/vetrate" element={<VetratePage />} />
				<Route path="/il-progetto" element={<ProgettoPage />} />
			</Routes>
		</Suspense>
	);
};

export default AppRoutes;
