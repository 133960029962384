import styles from './Gallery.module.scss';
import { GalleryColumn } from 'components';
import { PictureData, GalleryData } from 'types/data.types';

type Props = {
	pictures?: Array<PictureData>;
	skeleton?: boolean;
};

const Gallery = ({ pictures, skeleton = false }: Props) => {
	const sortedPics: GalleryData = {
		0: [],
		1: [],
		2: [],
	};

	let count = 0;
	pictures?.forEach((picture: PictureData) => {
		sortedPics[count].push(picture);
		count = count < 2 ? ++count : 0;
	});

	return (
		<section className={styles.gallery}>
			<GalleryColumn type="wide" pictures={sortedPics[0]} isSkeleton={skeleton} />
			<GalleryColumn type="thin" pictures={sortedPics[1]} isSkeleton={skeleton} />
			<GalleryColumn type="wide" pictures={sortedPics[2]} isSkeleton={skeleton} />
		</section>
	);
};

export default Gallery;
