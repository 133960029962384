import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import styles from './ArticleCard.module.scss';
import { ArticleData } from 'types/data.types';
import pictureCardPlaceholder from 'assets/images/picture-card-placeholder.png';

type Props = {
	data?: ArticleData;
	isSkeleton?: boolean;
};

const ArticleCard = ({ data, isSkeleton = false }: Props) => {
	const navigate = useNavigate();
	const date = `${dayjs(data?.date).format('DD/MM/YYYY')}`;
	const handleCardClick = () => {
		navigate(`/articles/${data?.id}`, { replace: true });
	};
	return (
		<div className={styles.articleCard} onClick={handleCardClick}>
			{!isSkeleton ? (
				<img
					className={styles.picture}
					src={(data?.picture && data.picture[0].url) || pictureCardPlaceholder}
					alt={data?.pictureAlt}
				/>
			) : (
				<Skeleton containerClassName={styles.picture} width={339.6} height={319} />
			)}
			<div className={styles.header}>
				<h3 className={styles.title}>{!isSkeleton ? data?.title : <Skeleton />}</h3>
				<p className={styles.author}>{!isSkeleton ? data?.author : <Skeleton />}</p>
				<p className={styles.date}>{!isSkeleton ? date : <Skeleton />}</p>
			</div>
			<p className={styles.description}>
				{!isSkeleton ? data?.description : <Skeleton count={5} />}
			</p>
		</div>
	);
};

export default ArticleCard;
