import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { EventData } from 'types/data.types';
import styles from './ChronologyItem.module.scss';

type Props = {
	event?: EventData;
	itemRef?: React.RefObject<HTMLDivElement>;
	isSkeleton?: boolean;
};

const ChronologyItem = ({ event, itemRef, isSkeleton = false }: Props) => {
	const navigate = useNavigate();
	const startDate = `${dayjs(event?.startDate).format('DD/MM/YYYY')}`;
	const endDate = event?.endDate ? ` - ${dayjs(event?.endDate).format('DD/MM/YYYY')}` : '';
	const id =
		dayjs(startDate).year() >= 1970
			? `${dayjs(startDate).year().toString().slice(0, -1)}0`
			: '1966';

	const handleClick = () => {
		navigate(`${event?.pageUrl}`);
	};

	return (
		<div className={styles.container} id={id || 'null'} ref={itemRef}>
			<h2 className={styles.title} onClick={handleClick}>
				{!isSkeleton ? event?.title : <Skeleton />}
			</h2>
			<p className={styles.date}>{!isSkeleton ? `${startDate}${endDate}` : <Skeleton />}</p>
			<p>{!isSkeleton ? event?.description : <Skeleton count={5} />}</p>
		</div>
	);
};

export default ChronologyItem;
